import { createBrowserRouter, RouteObject } from "react-router-dom";
import { lazy, Suspense } from "react";
import Layout from "../components/layout/Layout";
import {
  Dashboard,
  KViewer,
  LigandPrep,
  ProtienPreprocess,
  PDBInfo,
  Properties,
  PropertiesHome,
  DiffMolHome,
  LigandHome,
  PDBInfoDetails,
  DockingDashboard,
  PageNotFound,
  AutoDockVisualize,
  AutoDockViewer,
} from "../views";

import Login from "../components/user/Login";
import Signup from "../components/user/Signup";
import IndefiniteLoader from "../components/common/IndefiniteLoader";

// import Properties from "../components/propertyfilter/Properties";
import { ProtectedRoute, PageWrapper } from "../components";

const PropertiesEvaluations = lazy(
  () => import("../views/Properties/PropertiesEvaluations")
);
const ADMESubmitJobs = lazy(() => import("../views/AdmeTox/ADMESubmitJobs"));
const ADMEEvaluations = lazy(() => import("../views/AdmeTox/ADMEEvaluations"));
const DockingHome = lazy(() => import("../views/Docking/DockingHome"));
const LigandInteractionTable = lazy(
  () => import("../components/protein-ligand/LigandInteractionTable")
);
const PropertiesV2 = lazy(() => import("../views/Properties/PropertiesV2"));
const DockingClient = lazy(() => import("../views/Docking/DockingClient"));
const MolViewerHome = lazy(() => import("../views/DrViz/MolViewerHome"));
const ADMEPropertyFilter = lazy(
  () => import("../views/AdmeTox/ADMEPropertyFilter")
);
const DockingResults = lazy(() => import("../views/Docking/DockingResults"));
const PromiscuitySubmitJobs = lazy(
  () => import("../views/Promiscuity/PromiscuitySubmitJobs")
);
const GenieDashboard = lazy(() => import("../views/Genie/GenieDashboard"));
const PromiscuityVisualize = lazy(
  () => import("../views/Promiscuity/PromiscuityVisualize")
);
const PromiscuityViewer = lazy(
  () => import("../views/Promiscuity/PromiscuityViewer")
);
const RGroupsChemlet = lazy(() => import("../views/Chemlets/RGroupsChemlet"));
const ChemletDashboard = lazy(
  () => import("../views/Chemlets/ChemletDashboard")
);
const SequentialFiltering = lazy(
  () => import("../views/Chemlets/SequentialFiltering")
);
const Fragments = lazy(() => import("../views/Chemlets/Fragments"));
const MolSimilarity = lazy(() => import("../views/Chemlets/MolSimilarity"));
const PLIP = lazy(() => import("../views/Chemlets/PLIP"));
const MolProperties = lazy(() => import("../views/Chemlets/MolProperties"));
const ProfilePage = lazy(() => import("../views/ProfilePage/ProfilePage"));
const Pricing = lazy(() => import("../views/Pricing/pricing"));
const PropertiesHomeV3 = lazy(
  () => import("../views/Properties/PropertiesHomeV3")
);
const PropertiesVisualizeV3 = lazy(
  () => import("../views/Properties/PropertiesVisualizeV3")
);
const LeaderDashboard = lazy(() => import("../views/Leader/LeaderDashboard"));
const Molfil = lazy(() => import("../views/Molfil/Molfil"));
const LiftVisualizer = lazy(
  () => import("../views/LiftVisualizer/LiftVisualizer")
);
const LiftVisualResult = lazy(
  () => import("../views/LiftVisualizer/LiftVisualResult")
);
const LiftFilterMolecules = lazy(
  () => import("../views/LiftFilter/LiftFilterMolecules")
);
const LiftFilterVisualizer = lazy(
  () => import("../views/LiftFilter/LiftFilterVisualizer")
);
const MoleculeCopilot = lazy(
  () => import("../views/MoleculeCopilot/MoleculeCopilot")
);
const MoleculeCopilotResult = lazy(
  () => import("../views/MoleculeCopilot/MoleculeCopilotResult")
);
const CoPilotSessions = lazy(() => import("../views/Copilot/CoPilotSessions"));
const PropertyEditor = lazy(() => import("../views/Copilot/PropertyEditor"));
const RankerVisualize = lazy(() => import("../views/Ranker/RankerVisualize"));
const RankerSubmitJobs = lazy(() => import("../views/Ranker/RankerSubmitJobs"));
const RankerEvaluations = lazy(
  () => import("../views/Ranker/RankerEvaluations")
);
const LeaderReinvent = lazy(
  () => import("../views/leaderReinvent/LeaderReinvent")
);
const LeaderReinventVisualize = lazy(
  () => import("../views/leaderReinvent/LeaderReinventVisualize")
);
const BioisostericSubmitJobs = lazy(
  () => import("../views/Bioisosteric/BioisostericSubmitJobs")
);
const BioisoVisualize = lazy(
  () => import("../views/Bioisosteric/BioisoVisualize")
);
const MoleculeClustring = lazy(
  () => import("../views/Chemlets/MoleculeClustering")
);
const InternalDockingDashboard = lazy(
  () => import("../components/InternalDocking/InternalDockingCards")
);
const Hopper = lazy(() => import("../views/Chemlets/Hopper"));
const Genfil = lazy(() => import("../views/Chemlets/Genfil"));
const LodestarHome = lazy(() => import("../views/Lodestar/LodestarHome"));
const RBFEVisualize = lazy(() => import("../views/Lodestar/RBFEVisualize"));
const NCIVisualize = lazy(() => import("../views/Lodestar/NCIVisualize"));
const MoleculeDesignDashboard = lazy(
  () => import("../views/MoleculeDesign/MoleculeDesignDashboard")
);
const AdmetDashboard = lazy(() => import("../views/AdmeTox/AdmetDashboard"));

type MoonvizRoutes = RouteObject & {
  title?: string;
  children?: Array<RouteObject & MoonvizRoutes>;
};
export const routes: Array<MoonvizRoutes> = [
  {
    path: "/",
    element: (
      <ProtectedRoute section="Dashboard">
        <Dashboard />
      </ProtectedRoute>
    ),
    title: "Dashboard",
    id: "home",
  },
  {
    path: "virtual-screening",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <DockingDashboard />
      </ProtectedRoute>
    ),
    title: "Virtual Screening",
    id: "virtual-screening-dashboard",
  },
  // Docking Viewer
  {
    path: "virtual-screening/docking/viewer",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <DockingHome />
      </ProtectedRoute>
    ),
    title: "Docking Viewer",
    id: "docking-viewer",
  },
  {
    path: "virtual-screening/docking",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <InternalDockingDashboard />
      </ProtectedRoute>
    ),
    title: "Docking",
    id: "internal-docking-cards",
  },
  {
    path: "virtual-screening/genfil",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <Genfil />
      </ProtectedRoute>
    ),
    title: "GenFil",
    id: "genfil",
  },
  {
    path: "genie",
    element: (
      <ProtectedRoute section="De Novo Generation">
        <GenieDashboard />
      </ProtectedRoute>
    ),
    title: "De Novo Generation Dashboard",
    id: "genie-dashboard",
  },
  {
    path: "genie/maimol",
    element: (
      <ProtectedRoute section="De Novo Generation">
        <DiffMolHome />
      </ProtectedRoute>
    ),
    title: "MAIMol",
    id: "maimol",
  },
  {
    path: "genie/ranker",
    element: (
      <ProtectedRoute section="De Novo Generation">
        <RankerSubmitJobs />
      </ProtectedRoute>
    ),
    title: "Ranker",
    id: "ranker",
  },
  {
    path: "genie/ranker/visualize",
    element: (
      <ProtectedRoute section="De Novo Generation">
        <RankerVisualize />
      </ProtectedRoute>
    ),
    title: "Ranker",
  },
  {
    path: "genie/ranker/evaluations",
    element: (
      <ProtectedRoute section="De Novo Generation">
        <RankerEvaluations />
      </ProtectedRoute>
    ),
    title: "Ranker",
  },
  {
    path: "adme",
    element: (
      <ProtectedRoute section="ADMET">
        {/* <ADMESubmitJobs /> */}
        <AdmetDashboard />
      </ProtectedRoute>
    ),
    title: "ADME and Tox",
    id: "adme_tox",
  },
  {
    path: "adme/admet-pro",
    element: (
      <ProtectedRoute section="ADMET">
        <ADMESubmitJobs />
      </ProtectedRoute>
    ),
    title: "ADMET Pro",
    id: "admet-pro",
  },
  {
    path: "adme/admet-pro/evaluations",
    element: (
      <ProtectedRoute section="ADMET">
        <ADMEEvaluations />
      </ProtectedRoute>
    ),
    title: "ADMET Evaluation Results",
    id: "adme_tox_eval",
  },
  {
    path: "adme/admet-pro/visualize",
    element: (
      <ProtectedRoute section="ADMET">
        <ADMEPropertyFilter />
      </ProtectedRoute>
    ),
    title: "ADME and Tox",
  },
  {
    path: "molbench-copilot",
    element: (
      <ProtectedRoute section="MolBench Copilot">
        <CoPilotSessions />
      </ProtectedRoute>
    ),
    title: "MolBench Copilot",
  },
  {
    path: "screenie/properties",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <PropertiesHome />
      </ProtectedRoute>
    ),
    title: "Screenie",
    id: "properties-home",
  },
  {
    path: "virtual-screening/properties-v2",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <PropertiesHome />
      </ProtectedRoute>
    ),
    title: "Screenie",
    id: "properties-home-v2",
  },
  {
    path: "virtual-screening/properties-v3",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <PropertiesHomeV3 />
      </ProtectedRoute>
    ),
    title: "Screenie",
    id: "properties-home-v3",
  },
  {
    path: "virtual-screening/properties-v3/visualize",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <PropertiesVisualizeV3 />
      </ProtectedRoute>
    ),
    title: "Screenie",
    id: "properties-visualize-v3",
  },
  {
    path: "virtual-screening/properties/filter",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <Properties />
      </ProtectedRoute>
    ),
    title: "Screenie",
    id: "properties-filter",
  },
  {
    path: "/user/profile",
    element: <ProfilePage />,
    title: "Profile",
    id: "profile",
  },

  {
    path: "virtual-screening/properties-v2/filter",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <PropertiesV2 />
      </ProtectedRoute>
    ),
    title: "Screenie",
    id: "properties-filter-v2",
  },

  {
    path: "pricing",
    element: <Pricing />,
    title: "Pricing",
    id: "pricing",
  },

  {
    path: "user/login",
    element: (
      // <ProtectedRoute  >
      <Login />
      // </ProtectedRoute>
    ),
    title: "Login",
    id: "login",
  },
  {
    path: "user/signup",
    element: (
      // <ProtectedRoute >
      <Signup />
      // </ProtectedRoute>
    ),
    title: "SignUp",
    id: "signup",
  },

  // Docking client
  {
    path: "virtual-screening/docking/autodock",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <DockingClient />
      </ProtectedRoute>
    ),

    title: "AutoDock Docking",
    id: "docking-autodock",
  },
  {
    path: "virtual-screening/docking/unidock",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <DockingClient />
      </ProtectedRoute>
    ),

    title: "UniDock Docking",
    id: "docking-unidock",
  },
  {
    path: "virtual-screening/docking/autodock/visualize",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <AutoDockVisualize />
      </ProtectedRoute>
    ),

    title: "Autodock Visualization",
    id: "docking-autodock-visualize",
  },
  {
    path: "virtual-screening/docking/unidock/visualize",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <AutoDockVisualize />
      </ProtectedRoute>
    ),

    title: "UniDock Visualization",
    id: "docking-unidock-visualize",
  },
  {
    path: "virtual-screening/docking/autodock/viewer",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <AutoDockViewer />
      </ProtectedRoute>
    ),

    title: "Autodock Viewer",
    id: "docking-autodock-viewer",
  },
  {
    path: "virtual-screening/docking/unidock/viewer",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <AutoDockViewer />
      </ProtectedRoute>
    ),

    title: "UniDock Viewer",
    id: "docking-unidock-viewer",
  },
  {
    path: "virtual-screening/docking/autodock/results",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <DockingResults />
      </ProtectedRoute>
    ),

    title: "AutoDock Results",
    id: "results-autodock",
  },
  {
    path: "virtual-screening/docking/unidock/results",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <DockingResults />
      </ProtectedRoute>
    ),

    title: "UniDock Results",
    id: "results-unidock",
  },
  {
    path: "ligand-interaction",
    element: (
      <ProtectedRoute section="Dashboard">
        <LigandInteractionTable />
      </ProtectedRoute>
    ),

    title: "Protein Ligand Interaction",
    id: "ligand-interaction",
  },
  {
    path: "virtual-screening/properties-v3/evaluations",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <PropertiesEvaluations />
      </ProtectedRoute>
    ),
    title: "Screenie Evaluation",
    id: "Properties Evaluation",
  },
  {
    path: "virtual-screening/molfil",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <Molfil />
      </ProtectedRoute>
    ),
    title: "MolFil",
    id: "molfil",
  },
  {
    path: "virtual-screening/lift/visualizer",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <LiftVisualizer />
      </ProtectedRoute>
    ),

    title: "LIFT Visualizer",
    id: "lift-visualizer",
  },

  {
    path: "virtual-screening/lift/visualizer/result",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <LiftVisualResult />
      </ProtectedRoute>
    ),

    title: "LIFT Visualizer",
    id: "lift-visualizer-result",
  },
  {
    path: "virtual-screening/lift/filter-molecules",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <LiftFilterMolecules />
      </ProtectedRoute>
    ),

    title: "LIFT Filter",
    id: "filter-molecules",
  },
  {
    path: "virtual-screening/lift/filter-molecules/visualize",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <LiftFilterVisualizer />
      </ProtectedRoute>
    ),

    title: "LIFT Filter",
    id: "filter-molecules-visualize",
  },
  {
    path: "leader",
    element: (
      <ProtectedRoute section="Lead Optimisation">
        <LeaderDashboard />
      </ProtectedRoute>
    ),

    title: "Lead Optimisation",
    id: "leader",
  },
  // {
  //   path: "leader/interactions",
  //   element: (
  //     <ProtectedRoute section="Lead Optimisation">
  //        <InteractionAdderHome/>
  //     </ProtectedRoute>
  //   ),

  //   title: "Interaction Adder",
  //   id: "interaction-adder",
  // },
  {
    path: "molecule-design",
    element: (
      <ProtectedRoute section="Molecule Design">
        <MoleculeDesignDashboard />
      </ProtectedRoute>
    ),

    title: "Molecule Design",
    id: "molecule-design",
  },
  {
    path: "molecule-design/copilot",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <MoleculeCopilot />
      </ProtectedRoute>
    ),

    title: "Molecule CoPilot",
    id: "lab-molecule-copilot",
  },
  {
    path: "molecule-design/copilot/modifications",
    element: (
      <ProtectedRoute section="Lead Optimisation">
        <MoleculeCopilotResult />
      </ProtectedRoute>
    ),

    title: "Molecule CoPilot",
    id: "molecule-modifications",
  },
  {
    path: "molecule-design/molbench",
    element: (
      <ProtectedRoute section="Molecule Design">
        <CoPilotSessions />
      </ProtectedRoute>
    ),

    title: "MolBench",
    id: "molecule-design-molbench",
  },
  {
    path: "molecule-design/molbench/editor/:sessionId",
    element: (
      <ProtectedRoute section="Molecule Design">
        <PropertyEditor />
      </ProtectedRoute>
    ),

    title: "MolBench Editor",
    id: "molbench-editor",
  },

  {
    path: "leader/bioisosteric",
    element: (
      <ProtectedRoute section="Lead Optimisation">
        <BioisostericSubmitJobs />
      </ProtectedRoute>
    ),

    title: "BioSwap",
    id: "bioisosteric-replacement",
  },
  {
    path: "leader/bioisosteric/visualize",
    element: (
      <ProtectedRoute section="Lead Optimisation">
        <BioisoVisualize />
      </ProtectedRoute>
    ),

    title: "BioSwap",
    id: "bioisosteric-replacement-visualize",
  },

  // Stand-Alone Molbench: We can use it in future if we need it.
  // {
  //   path: "lab/molbench",
  //   element: (
  //     <ProtectedRoute section="MoleculeAI Labs">
  //       <MolEditor></MolEditor>
  //     </ProtectedRoute>
  //   ),

  //   title: "MolBench",
  //   id: "lab-molbench",
  // },
  {
    path: "lab/lift",
    element: (
      <ProtectedRoute section="LIFT">
        <PLIP></PLIP>
        {/* <LiftDashboard /> */}
      </ProtectedRoute>
    ),

    title: "LIFT",
    id: "LIFT",
  },
  {
    path: "leader/r-groups",
    element: (
      <ProtectedRoute section="R Groups">
        <RGroupsChemlet />
      </ProtectedRoute>
    ),

    title: "SubstituteR",
    id: "chemlet-rgroups",
  },
  {
    path: "lab",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <ChemletDashboard />
      </ProtectedRoute>
    ),
    title: "MoleculeAI Labs",
    id: "lab-dashboard",
  },
  {
    path: "lab/seq-filtering",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <SequentialFiltering />
      </ProtectedRoute>
    ),

    title: "Sequential Ligand Filtering",
    id: "chemlet-seq-filtering",
  },
  {
    path: "lab/fragments",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <Fragments />
      </ProtectedRoute>
    ),

    title: "Fragment-based Ligand Design",
    id: "fragments",
  },
  {
    path: "lab/mol-similarity",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <MolSimilarity />
      </ProtectedRoute>
    ),

    title: "Molecular Similarity",
    id: "mol-similarity",
  },
  {
    path: "lab/mol-props",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <MolProperties />
      </ProtectedRoute>
    ),

    title: "Molecular Properties",
    id: "mol-props",
  },
  // Viewer
  {
    path: "lab/mol-viewer",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <MolViewerHome />
      </ProtectedRoute>
    ),
    title: "Dr Viz",
    id: "mol-viewer",
  },
  {
    path: "lab/mol-viewer/viewer",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <KViewer />
      </ProtectedRoute>
    ),
    title: "Dr Viz",
    id: "viewer",
  },
  {
    path: "lab/pdb/prep",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <ProtienPreprocess />
      </ProtectedRoute>
    ),
    title: "Protein Prep",
    id: "protein-prep",
  },
  // Protien Info
  {
    path: "lab/protein-info",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <PDBInfo />
      </ProtectedRoute>
    ),
    title: "Protein Info",
    id: "protein-info",
  },
  {
    path: "lab/protein-info/details",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <PDBInfoDetails />
      </ProtectedRoute>
    ),
    title: "Protein Info",
    id: "protein-info-details",
  },
  // Ligand
  {
    path: "lab/ligand",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <LigandHome />
      </ProtectedRoute>
    ),
    title: "Ligand Prep",
    id: "ligand",
  },

  {
    path: "lab/ligand/prep",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <LigandPrep />
      </ProtectedRoute>
    ),
    title: "Ligand Prep",
    id: "ligand-prep",
  },
  {
    path: "lab/promiscuity",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <PromiscuitySubmitJobs />
      </ProtectedRoute>
    ),
    title: "Promiscuity Filter",
    id: "Promiscuity",
  },
  {
    path: "lab/promiscuity/visualize",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <PromiscuityVisualize />
      </ProtectedRoute>
    ),

    title: "Promiscuity Filter Visualization",
    id: "promiscuity-visualize",
  },
  {
    path: "lab/promiscuity/viewer",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <PromiscuityViewer />
      </ProtectedRoute>
    ),

    title: "Promiscuity Filter Visualization",
    id: "promiscuity-viewer",
  },
  {
    path: "lab/molecule-clustering",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <MoleculeClustring />
      </ProtectedRoute>
    ),

    title: "ClusterPro",
    id: "molecule-clustering",
  },
  {
    path: "lab/hopper",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <Hopper />
      </ProtectedRoute>
    ),

    title: "Hopper",
    id: "hopper",
  },
  {
    path: "leader/leader",
    element: (
      <ProtectedRoute section="Lead Optimisation">
        <LeaderReinvent />
      </ProtectedRoute>
    ),
    title: "Leader",
    id: "leader-reinvent",
  },
  {
    path: "leader/leader/visualize",
    element: (
      <ProtectedRoute section="Lead Optimisation">
        <LeaderReinventVisualize />
      </ProtectedRoute>
    ),
    title: "Leader",
    id: "leader-visualize",
  },
  {
    path: "lab/lodestar",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <LodestarHome />
      </ProtectedRoute>
    ),

    title: "Lodestar",
    id: "lodestar",
  },
  {
    path: "lab/lodestar/rbfe/visualize",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <RBFEVisualize />
      </ProtectedRoute>
    ),
    title: "Lodestar",
    id: "lodestar-rbfe-visualize",
  },
  {
    path: "lab/lodestar/md/visualize",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <KViewer />
      </ProtectedRoute>
    ),

    title: "Lodestar",
    id: "lodestar-md-visualize",
  },
  {
    path: "lab/lodestar/nci/visualize",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <NCIVisualize />
      </ProtectedRoute>
    ),

    title: "Lodestar",
    id: "lodestar-nci-visualize",
  },
  {
    path: "*",
    element: <PageNotFound />,
    title: "Page Not Found",
    id: "404",
  },
];

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    id: "root",
    children: routes.map(({ path, element, id, title, children }) => ({
      path,
      element: (
        <Suspense fallback={<IndefiniteLoader state={true} />}>
          <PageWrapper title={title}>{element}</PageWrapper>
        </Suspense>
      ),
      id,
      children: children ? [...children] : [],
    })),
  },
]);

export { router };
