import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

type LoadersPropType = {
  type: "table";
  columns?: number;
  rows?: number;
};

function Loaders({ type, columns = 5, rows = 2 }: LoadersPropType) {
  const LOADER_TYPES = {
    table: "table",
  };
  const renderLoader = {
    [LOADER_TYPES.table]() {
      return (
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {[...Array(columns)].map((col, idx) => (
                <TableCell key={idx}>
                  <Skeleton />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {[...Array(rows).keys()].map((row, idx) => (
              <TableRow
                key={idx}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {[...Array(columns).keys()].map((col, idx) => (
                  <TableCell key={idx}>
                    <Skeleton />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    },
  };

  return renderLoader[type]();
}

export default Loaders;
