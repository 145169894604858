import "molstar/build/viewer/molstar.css";
// import './index.css'

import { Viewer } from "../components";
import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useLocation } from "react-router-dom";

function KViewer() {
  let viewer = new Viewer();
  const state = useLocation();

  const [pdbId, setPDBId] = useState(state?.state?.pdbID);

  useEffect(() => {
    ////console.log("pp",pdbId);

    async function initViewer() {
      await viewer.init("viewer", {
        layoutShowControls: true,
        viewportShowExpand: true,
        collapseRightPanel: false,
        collapseLeftPanel: false,
        viewportShowSelectionMode: true,
        pdbProvider: "pdbe",
        emdbProvider: "pdbe",
      });
    }

    initViewer().then(() => {
      if (pdbId) {
        viewer.loadPdb(pdbId);
      } else if (state?.state?.pdbFile) {
        if (state?.state?.trajectoryFile) {
          
          const readPdbTrajFile = async (pdbfile: File, trajfile: File) => {
           
            try {
              const pdbfileContent = await pdbfile.text();
              const reader = new FileReader();

              reader.onload = function (e) {
                const binaryData = e.target.result;
                viewer.loadTrajectory({
                  model: {
                    kind: "model-data",
                    data: pdbfileContent,
                    format: state?.state?.FileType
                  },
                  coordinates: {
                    kind: "coordinates-data",
                    data: binaryData,
                    format: "xtc",
                  },
                  preset: "all-models", // or 'default'
                });
              };
              reader.readAsArrayBuffer(trajfile);
            } catch (error) {
              console.error("Error reading file:", error);
            }
          };
          readPdbTrajFile(state?.state?.pdbFile, state?.state?.trajectoryFile);
        } else {
          const readPdbFile = async (file: File) => {
            try {
              const fileContent = await file.text();
              // console.log("fileContent",fileContent);
              // console.log("state?.state?.FileType",state?.state?.FileType);
              viewer.loadStructureFromData(fileContent, state?.state?.FileType);
            } catch (error) {
              console.error("Error reading file:", error);
            }
          };
          readPdbFile(state?.state?.pdbFile);
        }
      }
    });
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item m={3} md={12}>
        <div id="viewer"></div>
      </Grid>
    </Grid>
  );
}

export default KViewer;
