import { SERVICE_URL } from "../../config";
import http from "../../net/http-common";

export const fetchSessionInfo = async (sessionId: string) => {
  try {
    return http.get(
      `${SERVICE_URL}/copilot_service/copilot/get-session_info/${sessionId}/`,
      {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "http://localhost:3000",
        },
      }
    );
  } catch (error) {
    // console.log("error : ", error);
  }
};

export const removeSession = async (sessionId: string) => {
  try {
    return http.delete(
      `${SERVICE_URL}/copilot_service/copilot/remove-session/${sessionId}/`,
      {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "http://localhost:3000",
        },
      }
    );
  } catch (error) {
    // console.log("error : ", error);
  }
};

export const fetchResults = async (sessionId: string) => {
  try {
    return http.get(
      `${SERVICE_URL}/copilot_service/copilot/get-results/${sessionId}/`,
      {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "http://localhost:3000",
        },
      }
    );
  } catch (error) {
    // console.log("error : ", error);
  }
};

export const addResults = async (requestObj: any) => {
  try {
    const inputSmiles = requestObj.input_smiles;
    delete requestObj.input_smiles;

    return http.post(
      `${SERVICE_URL}/copilot_service/copilot/add-results?input_smiles=${encodeURIComponent(
        inputSmiles
      )}`,
      requestObj.results,
      {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
  } catch (error) {
    // console.log("error : ", error);
  }
};

export const updateResult = async (requestObj: any) => {
  try {
    const resultId = requestObj.result_id;
    delete requestObj.result_id;
    return http.patch(
      `${SERVICE_URL}/copilot_service/copilot/update-result/${resultId}`,
      requestObj,
      {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
  } catch (error) {
    // console.log("error : ", error);
  }
};

export const removeResult = async (requestObj: any) => {
  try {
    return http.delete(
      `${SERVICE_URL}/copilot_service/copilot/remove-result/${requestObj.resultId}?session_id=${requestObj.sessionId}`,
      {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
  } catch (error) {
    // console.log("error : ", error);
  }
};

export const fetchAllModifications = async (sessionId: string) => {
  try {
    return http.get(
      `${SERVICE_URL}/copilot_service/copilot/get-all-modifications/${sessionId}/`,
      {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "http://localhost:3000",
        },
      }
    );
  } catch (error) {
    // console.log("error : ", error);
  }
};

export const addModifications = async (requestObj: any) => {
  try {
    return http.post(
      `${SERVICE_URL}/copilot_service/copilot/add-modifications`,
      requestObj,
      {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
        },
      }
    );
  } catch (error) {
    // console.log("error : ", error);
  }
};

export const getPDBFile = async (requestObj: any) => {
  try {
    return http.get(
      `${SERVICE_URL}/copilot_service/copilot/get-pdb-file?session_id=${requestObj.session_id}&uid=${requestObj.uid}`,
      {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "http://localhost:3000",
        },
      }
    );
  } catch (error) {
    // console.log("error : ", error);
  }
};

export const getCopilotSessionInfo = async (requestObj: any) => {
  try {
    return http.get(
      `${SERVICE_URL}/copilot_service/copilot/get-copilot-session-info?job_id=${requestObj.jobId}&model=${requestObj.model}`,
      {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
  } catch (error) {
    // console.log("error : ", error);
  }
};

export const createCopilotSessionViaBridge = async (requestObj: any) => {
  try {
    return http.post(
      `${SERVICE_URL}/copilot_service/copilot/create-model-session`,
      requestObj,
      {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
  } catch (error) {
    // console.log("error : ", error);
  }
};
