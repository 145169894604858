import {
  Box,
  // Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import theme from "../../theme/theme";
import { navItems } from "../layout/Navbar";
import "../../css/navbar.css";
import { useUserAuth } from "../../context";
import { freeWorkflows } from "../../context/authentication/UserAuthContext";
import {
  isNotSubscribed,
  isWorkflowRestricted,
} from "../../context/authentication/userWorkflowValidation";
import { DashBoardManager } from "./HelperModule";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

const dashboardCards = navItems
  .filter((item) => item.label !== "Dashboard")
  .map((item) => ({
    label: item.label,
    link: item.link,
    description: item.description,
    dashIcon: item.dashIcon,
    navlabel: item.navlabel,
  }));

export const DashCard = ({
  label,
  link,
  description,
  dashIcon,
  section,
  navlabel,
  handleRedirection,
}: {
  label: string;
  link: string;
  description: string;
  dashIcon: any;
  section: string;
  navlabel: string;
  handleRedirection?: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    navlabel: string,
    section: string
  ) => any;
}) => {
  // Create an instance of the class
  const dashBoardManager = new DashBoardManager();

  // Get the coming soon modules
  const comingSooonModules = dashBoardManager.getComingSoonModules();
  const allAiModules = dashBoardManager.getAIModules();

  return (
    <React.Fragment>
      <CardContent
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          p: 3.75,
          pb: 0,
          height: "100%",
        }}
      >
        <Box sx={{ width: 1, position: "relative" }}>
          <Box
            component={"img"}
            src={dashIcon}
            className="dashIcon" //
            sx={{
              display: "block",
              m: "auto",
              width: 1,
              border: "1px solid #EAEAEC",
              borderRadius: "6px",
            }}
          />
          {/* {
            allAiModules.includes(label) && (
              <Box
              sx={{
                position: "absolute",
                top: "-30px",
                // left: 0,
                right: 0,
                p: 0.3,
                // backgroundColor: "#582FF1", // Semi-transparent blue background
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "6px", // Match the image border radius
               
                
              }}
            >
           
            <AutoAwesomeIcon fontSize="small"  sx={{color:"#582FF1"}}  />
              <Typography
                variant="h6"
                sx={{   fontSize: "0.9rem", textAlign: "center",ml:1 }}
              >
               AI-Powered
              </Typography>
            </Box>
            )
          } */}

          {comingSooonModules.includes(label) && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: "55%",
                p: 0.3,
                backgroundColor: "#582FF1", // Semi-transparent blue background
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "6px", // Match the image border radius
              }}
            >
              <Typography
                variant="h6"
                sx={{ color: "white", fontSize: "0.9rem", textAlign: "center" }}
              >
                Coming Soon
              </Typography>
            </Box>
          )}
        </Box>

        <Box>
          <Typography variant={"h6"} fontWeight={700}>
            {label}
          </Typography>
          <Typography sx={{ fontSize: "0.9rem" }} color={"GrayText"}>
            {description}
          </Typography>
        </Box>

        {allAiModules.includes(label) && (
          <Box
            sx={{
              py: 0.3,
              px:1,
              background: "linear-gradient(93deg, #6540EE 7.27%, #8062EE 77.03%)",  
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              borderRadius: "6px", // Match the image border radius
              color: "white",
              width: "fit-content", // Automatically adjust width based on content
              fontSize: "0.5rem",
              gap:"5px"
            }}
          >
            <AutoAwesomeIcon fontSize={"small"} />

            <Typography   fontSize={"0.9rem"} fontWeight={"bold"} >
              AI-powered
            </Typography>
          </Box>
        )}
      </CardContent>
      <CardActions sx={{ p: 3.75, pt: 0, width: "100%", mt: "auto" }}>
        {!comingSooonModules.includes(label) && (
          <>
            <Typography
              sx={{
                textDecoration: "none",
                color: "#582FF1",
                fontWeight: 700,
              }}
            >
              Click Here
            </Typography>
            <Typography
              sx={{
                textDecoration: "none",
                color: "#582FF1",
                fontWeight: 700,
              }}
            >
              <i className="ri-arrow-right-line"></i>
            </Typography>
          </>
        )}
      </CardActions>
    </React.Fragment>
  );
};

function DashboardCard() {
  const {
    accessWorkflows,
    worklowValidation,
    userSubscription,
    razorpaySubInfo,
  } = useUserAuth();

  const [section, setSection] = useState<string>("Dashboard");
  const navigate = useNavigate();

  const handleRedirection = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    navlabel: string,
    section: string
  ): any => {
    if (
      section === "Dashboard" &&
      isWorkflowRestricted(
        worklowValidation,
        navlabel,
        freeWorkflows,
        accessWorkflows
      ) &&
      isNotSubscribed(
        navlabel,
        freeWorkflows,
        userSubscription,
        razorpaySubInfo
      )
    ) {
      e.preventDefault();
      navigate("/pricing");
    }

    return null;
  };

  return (
    <Box>
      <Grid container spacing={3}>
        {dashboardCards.map((item, index) => {
          return (
            <Grid item key={index} xs={12} md={6} lg={4} xl={3}>
              <Link
                to={item.link}
                onClick={(e) => handleRedirection(e, item.navlabel, section)}
              >
                <Tooltip
                  title={
                    isWorkflowRestricted(
                      worklowValidation,
                      item.navlabel,
                      freeWorkflows,
                      accessWorkflows
                    ) &&
                    isNotSubscribed(
                      item.navlabel,
                      freeWorkflows,
                      userSubscription,
                      razorpaySubInfo
                    ) ? (
                      <Typography
                        sx={{
                          fontSize: "16px",
                        }}
                      >
                        Click to Subscribe
                      </Typography>
                    ) : (
                      ""
                    )
                  }
                  placement="top-end"
                  sx={{
                    bgcolor: "success",
                  }}
                  arrow
                >
                  <Card
                    variant="outlined"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: 2,
                      flex: "1 0 0",
                      borderRadius: "18px",
                      border: "1px solid var(--shade-200, #E7EBF9)",
                      height: "100%",
                      "&:hover": {
                        boxShadow: 7,
                      },
                      opacity:
                        isNotSubscribed(
                          item.navlabel,
                          freeWorkflows,
                          userSubscription,
                          razorpaySubInfo
                        ) &&
                        isWorkflowRestricted(
                          worklowValidation,
                          item.navlabel,
                          freeWorkflows,
                          accessWorkflows
                        )
                          ? 0.3
                          : 1,
                    }}
                  >
                    <DashCard
                      {...item}
                      section={section}
                      handleRedirection={handleRedirection}
                    />
                  </Card>
                </Tooltip>
              </Link>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

export default DashboardCard;
