import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useApp, useUserAuth } from "../../context";
import { ListItemIcon } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useState } from "react";
import Tooltip from "@mui/material/Tooltip";

import "../../css/dashcards.css";
import UserMenu from "./UserMenu";
import theme from "../../theme/theme";
import { freeWorkflows } from "../../context/authentication/UserAuthContext";
import {
  isNotSubscribed,
  isWorkflowRestricted,
} from "../../context/authentication/userWorkflowValidation";
import GlobalAlert from "./GlobalAlert";

import InfoIcon from "@mui/icons-material/Info";

interface Props {
  window?: () => Window;
  handleDrawerToggle: (value: string) => void;
}

type NavItemType = {
  label: string;
  link: string;
  description?: string;
  icon?: any;
  dashIcon?: any;
  navlabel?: string;
};

type NavItemProps = {
  item: NavItemType;
};

const drawerWidth = 300;
const navItems: Array<NavItemType> = [
  {
    label: "Dashboard",
    navlabel: "Dashboard",
    link: "/",
    icon: "/images/icons/DashIconV2.svg",
  },

  {
    label: "De Novo Generation",
    navlabel: "De Novo Generation",
    link: "genie",
    icon: "/images/icons/diffmol.svg",
    dashIcon: "/images/new-dashcards-images/denovo_dashcard_moleculegen.png",
    description:
      "AI-driven module for de-novo generation of target-aware drug like molecule",
  },
  {
    label: "Virtual Screening",
    navlabel: "Virtual Screening",
    link: "virtual-screening",
    icon: "/images/icons/property-filter.svg",
    description:
      "AI-enhanced virtual screening: Smart tech meets computational chemistry to sift promising molecules",
    dashIcon:
      "/images/new-dashcards-images/virtual_screening_dashcard_moleculegen.png",
  },
  {
    label: "ADMET Prediction",
    navlabel: "ADMET Prediction",
    link: "adme",
    icon: "/images/icons/AdmeIconV2.svg",
    dashIcon: "/images/new-dashcards-images/admetprediction_dashcard_moleculegen.png",
    description: "AI-models based prediction of ADME and Toxicity properties",
  },
  {
    label: "Lead Optimisation",
    navlabel: "Lead Optimisation",
    link: "leader",
    icon: "/images/icons/LeaderIconV2.svg",
    dashIcon:
      "/images/new-dashcards-images/lead_optimisation_dashcard_moleculegen.png",
    description: "Perform Lead optimization using Generative AI",
  },
  {
    label: "Molecule Design",
    link: "molecule-design",
    icon: "/images/icons/ligand-prep.svg",
    description:
      "Interactively design and edit molecules and compare different molecules on the basis of properties like ligand-protein interactions and docking scores",
    dashIcon:
      "/images/new-dashcards-images/molecule_design_dashcard_moleculegen.png",
    navlabel: "Molecule Design",
  },
  {
    label: "MoleculeAI Labs",
    navlabel: "MoleculeAI Labs",
    link: "lab",
    icon: "/images/icons/LeaderIconV2.svg",
    dashIcon: "/images/new-dashcards-images/lab_dashcard_moleculegen.png",
    description: "Get a sneak preview at exciting things in development",
  },
];

export { navItems };
export default function Navbar(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const appState = useApp();

  const [drawerOpen, setDrawerOpen] = useState(true);
  const navigate = useNavigate();

  const {
    accessWorkflows,
    user,
    worklowValidation,
    userSubscription,
    razorpaySubInfo,
  } = useUserAuth();

  // console.log(userSubscription,razorpaySubInfo);
  const handleDrawerClose = () => {
    setDrawerOpen((drawerOpen) => !drawerOpen);
    if (!drawerOpen) props.handleDrawerToggle("300");
    else props.handleDrawerToggle("0");
  };

  useEffect(() => {
    //console.log(appState);
  }, [appState]);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleRedirection = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    item: any
  ): any => {
    if (!user) {
      e.preventDefault();
      // navigate('/');
    } else if (
      isWorkflowRestricted(
        worklowValidation,
        item.navlabel,
        freeWorkflows,
        accessWorkflows
      ) &&
      isNotSubscribed(
        item.navlabel,
        freeWorkflows,
        userSubscription,
        razorpaySubInfo
      )
    ) {
      e.preventDefault();
      navigate("/pricing");
    }

    return null;
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Toolbar sx={{ py: "15px", height: "94px" }}>
        <Box
          component={"img"}
          sx={{ maxWidth: "85%" }}
          src={"/images/MoleculeGEN_White_Grey_Logo.png"}
          alt={"Molecule Gen"}
        ></Box>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon sx={{ color: "white" }} fontSize="large" />
        </IconButton>
      </Toolbar>
      <Divider />
      <List sx={{ p: 2.5 }}>
        {navItems.map((item, index) => {
          return (
            <ListItem key={index} disablePadding>
              <Tooltip
                title={
                  !user ? (
                    <Typography
                      sx={{
                        fontSize: "16px",
                      }}
                    >
                      Please Sign In
                    </Typography>
                  ) : isWorkflowRestricted(
                      worklowValidation,
                      item.navlabel,
                      freeWorkflows,
                      accessWorkflows
                    ) &&
                    isNotSubscribed(
                      item.navlabel,
                      freeWorkflows,
                      userSubscription,
                      razorpaySubInfo
                    ) ? (
                    <Typography
                      sx={{
                        fontSize: "16px",
                      }}
                    >
                      Click to Subscribe
                    </Typography>
                  ) : (
                    ""
                  )
                }
                placement="right"
                arrow
              >
                <NavLink
                  to={item.link}
                  style={{
                    width: "100%",
                  }}
                  className={"sidebar-nav-link"}
                  onClick={(e) => handleRedirection(e, item)}
                >
                  <ListItemButton
                    sx={{
                      p: 2,
                      "&:hover": {
                        background: "transparent",
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: "36px",
                        height: "100%",
                        borderRadius: 10,
                      }}
                    >
                      <img
                        src={item.icon}
                        alt={"menu icon"}
                        style={{ borderRadius: 5 }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={item.navlabel}
                      primaryTypographyProps={{
                        fontWeight: 600,
                      }}
                      sx={{
                        color: "white",
                        opacity: !user
                          ? 0.5
                          : isNotSubscribed(
                              item.navlabel,
                              freeWorkflows,
                              userSubscription,
                              razorpaySubInfo
                            ) &&
                            isWorkflowRestricted(
                              worklowValidation,
                              item.navlabel,
                              freeWorkflows,
                              accessWorkflows
                            )
                          ? 0.5
                          : 1,
                      }}
                    />
                  </ListItemButton>
                </NavLink>
              </Tooltip>
            </ListItem>
          );
        })}
      </List>

      <Link
        to="https://docs.moleculeai.com/"
        style={{
          position: "fixed",
          bottom: 0,
          color: "white",
          display: "flex",
          alignItems: "center",
          gap: "5px",
          fontSize: "1.2rem",
          margin: "0em 0em 2em 2em",
        }}
        target="_blank"
      >
        <InfoIcon fontSize="medium" /> <Box sx={{ mt: 0.5 }}>Help</Box>
      </Link>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* Sidebar Nav */}
      <Box component="nav">
        {/* <Drawer
          container={container}
          variant="temporary"
          open={true}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            ".MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer> */}

        <Drawer
          variant="persistent"
          anchor="left"
          sx={{
            display: { sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: theme.palette.common.black,
            },
          }}
          open={drawerOpen}
        >
          {drawer}
        </Drawer>
      </Box>
      <GlobalAlert />
      {/* App Header */}
      <AppBar
        component="nav"
        position="fixed"
        color="transparent"
        sx={{
          width: { sm: drawerOpen ? `calc(100% - ${drawerWidth}px)` : "100%" },
          ml: { sm: `${drawerWidth}px` },
          py: "15px",
          backgroundColor: "#EAEAEC",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#EAEAEC",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerClose}
            sx={{
              mr: 2,
              // marginLeft: "0px",
              display: drawerOpen ? "none" : "block",
            }}
          >
            <MenuIcon fontSize="large" />
          </IconButton>
          <Typography
            variant="h4"
            component="div"
            sx={{
              flexGrow: 1,
              display: { sm: "block" },
              pt: 1,
              fontWeight: 700,
            }}
          >
            {appState.currentPageTitle}
          </Typography>

          <Box sx={{ display: { sm: "flex" }, gap: 3 }} component={"nav"}>
            <UserMenu />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
