import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Alert,
  Snackbar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DiffMolFileInputs, JobsTable } from "../../components";

import { useUserAuth } from "../../context";
import Loaders from "../../components/common/Loaders";
import { TabContext, TabList } from "@mui/lab";
import { Tab } from "@mui/material";
import { TabPanel } from "../../components";
import { errorMessages } from "../../common_variables/ErrorMsgs";
import IndefiniteLoader from "../../components/common/IndefiniteLoader";
import { JOB_SERVER_URL } from "../../config";
import { useLocation } from "react-router-dom";
import http from "../../net/http-common";

function DiffMolHome() {
  const MODEL_NAME = "targetdiff";

  const [rows, setRows] = useState<[]>();
  const [doLoadJobs, setDoLoadJobs] = useState(true);
  const [modelName, setModelName] = useState<string>("");

  const { pathname } = useLocation();

  const { user } = useUserAuth();

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
    handleSubmitMsg(false);
  };

  const [tabValue, setTabValue] = useState("0");
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [showSubmitMsg, SetShowSubmitMsg] = useState(false);
  const [jobID, setJobId] = useState(-1);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showError, setShowError] = useState(false);
  const [moduleContent, setModuleContent] = useState<any>([]);

  //console.log(jobID);

  const handleSubmitMsg = (status: boolean) => {
    SetShowSubmitMsg(status);
  };

  const handleErrorMsg = (status: boolean) => {
    setShowError(status);
    if (status) setErrorMsg(errorMessages.submitJobError);
  };
  const handleJobId = (id: any) => {
    setJobId(id);
  };
  const handleLoadJobs = (status: boolean) => {
    setDoLoadJobs(status);
  };
  const handleBackdrop = (status: boolean) => {
    setRequestInProgress(status);
  };

  // targetdiff Content
  const targetDiffChipTabs = [
    {
      id: "0",
      label: "description",
      value: (
        <>
          <Typography>
            DiffMol is a target-aware diffusion-based de novo ligand generation
            module that generates small molecules that are best suited to bind
            to specific binding sites in a particular protein. It is based on
            TargeDiff[1], and leverages SE(3)-equivariant networks for
            generating molecules with realistic structures and improved binding
            affinities.
          </Typography>
          <Box
            component="img"
            src="/images/dashcard-images/diffmol-dashcard.png"
            alt="Protein Info Visual"
            sx={{
              width: "90%", // Increases the width to 100% of the parent containerimages
              height: "auto",
              mt: 4,
              mb: 2,
              mx: "auto",
              display: "block",
              p: 2,
            }}
          />
          <Typography sx={{ pb: 6 }}>
            <div id="userInputs">
              <h2>User Inputs:</h2>

              <div id="option1">
                <strong>Option 1:</strong>
                <p style={{ marginLeft: "30px" }}>
                  1). <b>Protein Structure (.pdb file): </b>
                  <br></br>
                  Upload the .pdb file containing the protein structure for
                  ligand generation.
                  <br></br>
                  <br></br>
                  2). <b>Ligand Structure (.sdf file):</b>
                  <br></br>
                  Provide the .sdf file of the ligand, ensuring it reflects the
                  conformation where the ligand is bound to the target site on
                  the provided protein structure. This is essential for
                  accurately identifying the ligand binding site on the protein.
                </p>
              </div>
              <div>OR</div>
              <br></br>
              <div id="option2">
                <strong>Option 2:</strong>
                <p style={{ marginLeft: "30px" }}>
                  1). <b>Protein Pocket (.pdb file): </b>
                  <br></br>A PDB file containing the residues of the target
                  protein's ligand-binding cavity or other druggable pockets of
                  interest, against which ligands are to be generated.
                </p>
              </div>
            </div>
          </Typography>
        </>
      ),
    },
    {
      id: "1",
      label: "References",
      value: (
        <>
          <Grid container item direction={"column"} mt={3}>
            {/* Add more references here */}
            <Typography variant={"body1"}>
              DiffMol is based on the diffusion-based tool, TargetDiff [1], that
              utilizes a 3D equivariant diffusion model for accurate,
              target-aware drug design.
            </Typography>
            <Typography variant={"body1"} mb={2}>
              It leverages SE(3)-equivariant networks for generating molecules
              with realistic structures and improved binding affinities to
              protein targets.
            </Typography>
            <Typography variant={"body1"} mb={4}>
              [1] Guan, Jiaqi, et al. "3D Equivariant Diffusion for Target-Aware
              Molecule Generation and Affinity Prediction.
              <i>International Conference on Learning Representations, 2023.</i>
            </Typography>
          </Grid>
        </>
      ),
    },
  ];

  // TagMol Content
  const tagMolChipTabs = [
    {
      id: "0",
      label: "description",
      value: (
        <>
          <Typography>
            TAGMol is an in-house, cutting-edge diffusion-based molecular
            generation module that generates target-specific, drug-like
            compounds with improved attributes such as Quantitative Estimation
            of Drug-likeness (QED), binding affinities, and synthetic
            accessibility (SA).
          </Typography>
          <Box
            component="img"
            src="/images/dashcard-images/tagMol-dashcard.png"
            alt="TagMOL Visualization"
            sx={{
              width: "90%", // Increases the width to 100% of the parent containerimages
              height: "auto",
              mt: 4,
              mb: 2,
              mx: "auto",
              display: "block",
              p: 2,
            }}
          />
          <Typography sx={{ pb: 6 }}>
            <div id="userInputs">
              <h2>User Inputs:</h2>

              <div id="option1">
                <strong>Option 1:</strong>
                <p style={{ marginLeft: "30px" }}>
                  1). <b>Protein Structure (.pdb file): </b>
                  <br></br>
                  Upload the .pdb file containing the protein structure for
                  ligand generation.
                  <br></br>
                  <br></br>
                  2). <b>Ligand Structure (.sdf file):</b>
                  <br></br>
                  Provide the .sdf file of the ligand, ensuring it reflects the
                  conformation where the ligand is bound to the target site on
                  the provided protein structure. This is essential for
                  accurately identifying the ligand binding site on the protein.
                </p>
              </div>
              <div>OR</div>
              <br></br>
              <div id="option2">
                <strong>Option 2:</strong>
                <p style={{ marginLeft: "30px" }}>
                  1). <b>Protein Pocket (.pdb file): </b>
                  <br></br>A PDB file containing the residues of the target
                  protein's ligand-binding cavity or other druggable pockets of
                  interest, against which ligands are to be generated.
                </p>
              </div>
            </div>
          </Typography>
        </>
      ),
    },
    {
      id: "1",
      label: "References",
      value: (
        <>
          <Grid container item direction={"column"} mt={3}>
            {/* Add more references here */}
            <Typography variant={"body1"}>
              DiffMol is based on the diffusion-based tool, TargetDiff [1], that
              utilizes a 3D equivariant diffusion model for accurate,
              target-aware drug design.
            </Typography>
            <Typography variant={"body1"} mb={2}>
              It leverages SE(3)-equivariant networks for generating molecules
              with realistic structures and improved binding affinities to
              protein targets.
            </Typography>
            <Typography variant={"body1"} mb={4}>
              [1] Guan, Jiaqi, et al. "3D Equivariant Diffusion for Target-Aware
              Molecule Generation and Affinity Prediction.
              <i>International Conference on Learning Representations, 2023.</i>
            </Typography>
          </Grid>
        </>
      ),
    },
  ];

  // MAImol Content
  const MaiMolChipTabs = [
    {
      id: "0",
      label: "description",
      value: (
        <>
          <Typography>
            MAIMol is an in-house, cutting-edge diffusion-based molecular
            generation module that generates target-specific, drug-like
            compounds with improved attributes such as Quantitative Estimation
            of Drug-likeness (QED), binding affinities<sup className="super-script" >*</sup>, and synthetic
            accessibility (SA).
          </Typography>
          <Typography>
          <sup className="super-script" >*</sup>Docking scores are used as a part of the molecule generation
            pipeline to assess binding affinities. Docking scores are obtained
            rapidly for large numbers of molecules using the UniDock tool.
          </Typography>
          <Box
            component="img"
            src="/images/new-descriptive-images/maimol_descriptive.png"
            alt="MaiMol Visualization"
            sx={{
              width: "70%", // Increases the width to 100% of the parent containerimages
              height: "auto",
              mx: "auto",
              display: "block",
              p: 2,
              pb: 0,
            }}
          />
          <Typography sx={{ pb: 6 }}>
            <div id="userInputs">
              <h2>User Inputs:</h2>

              <div id="option1">
                <p style={{ marginLeft: "30px" }}>
                  1). <b>Protein Structure (.pdb file): </b>
                  <br></br>
                  Upload the co-crystal .pdb file containing the protein and
                  ligand complex .
                </p>
                <p style={{ marginLeft: "30px" }}>
                  2). <b>Protein Chain and Residue Pair </b>
                  <br></br>
                  Chose the chain and residue pair from the suggested drop down.
                </p>
              </div>
            </div>
          </Typography>
        </>
      ),
    },
    // {
    //   id: "1",
    //   label: "References",
    //   value: (
    //     <>

    //     </>
    //   ),
    // },
  ];

  useEffect(() => {
    if (!doLoadJobs || !user) return;
    ////console.log(jobID)

    if (modelName) {
      http
        .get(`${JOB_SERVER_URL}/userjobs`, {
          params: {
            user_id: user.uid,
            model_name: modelName ? modelName.toLowerCase() : "",
            start: "0",
            end: "10",
          },
          headers: {
            accept: "application/json",
          },
        })
        .then((res) => {
          setDoLoadJobs(false);
          setRows(res.data);
        })
        .catch((error) => {
          setShowError(true);
          setErrorMsg(errorMessages.jobTableError);
          setDoLoadJobs(false);
          setRows([]);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doLoadJobs, modelName]);

  useEffect(() => {
    const pathSegment = pathname.split("/");

    if (pathname.includes("lab")) {
      if (pathSegment[3] == "tagmol") {
        setModuleContent(tagMolChipTabs);

        setModelName("TagMOL");
      }
    } else if (pathSegment[2] == "targetdiff") {
      setModuleContent(targetDiffChipTabs);
      setModelName("TargetDiff");
    } else if (pathSegment[2] == "tagmol") {
      setModuleContent(tagMolChipTabs);

      setModelName("TagMOL");
    } else if (pathSegment[2] == "maimol") {
      setModuleContent(MaiMolChipTabs);
      setModelName("MAIMol");
    }
  }, [pathname]);

  return (
    <>
      <Snackbar
        open={showError}
        autoHideDuration={9000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <Grid container py={1} px={3} spacing={2} height={1}>
        <Grid item xs={12} md={8} sx={{ pt: 2, width: "100%" }} pl={3} display={"flex"}>
          <Card sx={{ height: 1 }}>
            <CardContent sx={{ p: 0 }}>
              <Grid item>
                <TabContext value={tabValue}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
                    <TabList
                      value={tabValue}
                      onChange={handleTabChange}
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      {moduleContent.map((tab: any, index: any) => (
                        <Tab key={index} label={tab.label} value={tab.id} />
                      ))}
                    </TabList>
                  </Box>

                  <Grid item sx={{ px: 2 }}>
                    {moduleContent.map((tab: any, index: any) => (
                      <TabPanel key={index} value={tab.id}>
                        <Typography>{tab.value}</Typography>
                      </TabPanel>
                    ))}
                  </Grid>
                </TabContext>
              </Grid>
              {/* /content */}
              <IndefiniteLoader state={requestInProgress} />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4} pt={0} px={0} display={"flex"}>
          <Card sx={{ height: 1, width: "100%" }}>
            <DiffMolFileInputs
              handleSubmitMsg={handleSubmitMsg}
              handleLoadJobs={handleLoadJobs}
              handleJobID={handleJobId}
              handleErrorMsg={handleErrorMsg}
              modelName={modelName}
            />
          </Card>
        </Grid>
        <Grid sx={{ width: "50vw", mx: "auto" }}>
          {showSubmitMsg && (
            <Alert
              onClose={() => {
                SetShowSubmitMsg(false);
              }}
              sx={{ mt: 2 }}
              variant="filled"
              severity="success"
            >{`Job submitted with id ${jobID}`}</Alert>
          )}
        </Grid>
        <Grid item xs={12} pt={2} width={1} px={0}>
          <Card sx={{ width: 1 }}>
            <CardContent>
              {doLoadJobs && <Loaders type={"table"} />}
              {!doLoadJobs && (
                <JobsTable
                  jobs={rows}
                  handleBackdrop={handleBackdrop}
                  setDoLoadJobs={setDoLoadJobs}
                  disableDownload={true}
                  disableVisualize={modelName === "MAIMol" ? true : false}
                  setShowError={setShowError}
                  setErrorMsg={setErrorMsg}
                ></JobsTable>
              )}
            </CardContent>
          </Card>
        </Grid>
        {/* //   {/* Examples */}
      </Grid>
      <IndefiniteLoader state={requestInProgress} />
    </>
  );
}

export default DiffMolHome;
