import {
  Grid,
  Box,
  TextField,
  Typography,
  Button,
  Card,
  CardContent,
  LinearProgress,
  Stack,
  Checkbox,
  FormControlLabel,
  Alert,
  FormHelperText,
  Snackbar,
} from "@mui/material";
import { saveAs } from "file-saver";
import axios from "axios";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { TabPanel } from "../../components";
import { TabContext, TabList } from "@mui/lab";
import { Tab } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import React, { useRef } from "react";
import { Viewer } from "../../components";
import { SERVER_URL } from "../../config";
import "../../css/preprocess.css";
import { useState, useEffect } from "react";
import http from "../../net/http-common";
import { RepresentationStyleHolder } from "../../utils/helpers";
import { errorMessages } from "../../common_variables/ErrorMsgs";
import IndefiniteLoader from "../../components/common/IndefiniteLoader";
import BetaModal from "../../components/common/BetaModal";
import { RUNTIME } from "../../config";


const GLOBAL_VARS = {
  MIN_PH: 0,
  MAX_PH: 14,
  DEFAULT_PH: 7,

  MIN_CONFORMER_NO: 1,
  MAX_CONFORMER_NO: 1000,
  DEFAULT_CONFORMER_NO: 32,

  MIN_MINIMIZATION_STEPS: 50,
  MAX_MINIMIZATION_STEPS: 10000,
  DEFAULT_MINIMIZATION_STEPS: 100,
};

function ProtienPreprocess() {
  const [pdbID, setPDBID] = React.useState("");
  const [requestInProgress, setRequestInProgress] = React.useState(false);
  let initialViewer = useRef<Viewer>();
  let postViewer = useRef<Viewer>();
  let minimizedViewer = useRef<Viewer>();
  const [fetchingAssembly, setFetchingAssembly] = useState(false);
  const [assembly, setAssembly] = useState("1");
  const [assemblies, setAssemblies] = useState<any>();
  const [phValue, setPhValue] = useState(GLOBAL_VARS.DEFAULT_PH);
  const [minSteps, setMinSteps] = useState(
    GLOBAL_VARS.DEFAULT_MINIMIZATION_STEPS
  );
  const [missingResidue, setMissingResidue] = useState(false);
  const [disulphideBonds, setDisulphideBonds] = useState(false);
  const [addCapTerminal, setAddCapTerminal] = useState(false);
  const [removeHET, setRemoveHET] = useState(false);
  const [initialPdb, setInitialPdb] = useState(false);
  const [finalpdb, setFinalPdb] = useState(false);
  const apiResponseRef = useRef(null);
  const [exportMsg, setExportMsg] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showError, setShowError] = useState(false);
  const [betaOpen, setBetaOpen] = useState(false);

  const runInProd = RUNTIME === "PROD";

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const showInitialProtein = async (assemblychange?: boolean) => {
    if (!assemblychange) {
      initialViewer.current = new Viewer();
      await initialViewer.current.init("viewer-1", {
        layoutShowControls: false,
        viewportShowExpand: false,
        collapseLeftPanel: true,
        layoutShowSequence: true,
      });
    }
    // console.log(assembly);
    //New added code for input change on changing assembly
    //
    const url = `https://www.ebi.ac.uk/pdbe/entry-files/download/${pdbID}.bcif`;
    const format = "cif";
    const isBinary = true;
    const representationStyle = {
      sequence: { coloring: "chain-id" }, // or just { }
      hetGroups: { kind: "spacefill" },
      water: { hide: true },
      snfg3d: { hide: false },
    } as RepresentationStyleHolder;
    initialViewer.current.setBackground(0xffffff);
    await initialViewer.current.load({
      url: url,
      format: format,
      isBinary: isBinary,
      assemblyId: assembly,
      representationStyle: representationStyle,
      assemblychange: assemblychange,
    }); //

    //previous code
    //initialViewer.current?.loadPdb(pdbID);
  };

  const showPreparedProtein = async () => {
    postViewer.current = new Viewer();
    await postViewer.current.init("viewer-2", {
      layoutShowControls: false,
      viewportShowExpand: false,
      collapseLeftPanel: true,
      layoutShowSequence: true,
    });

    postViewer.current?.loadStructureFromData(
      apiResponseRef.current.data.prepared_pdb,
      "pdb"
    );
  };

  const showMinimizedProtein = async () => {
    minimizedViewer.current = new Viewer();
    await minimizedViewer.current.init("viewer-3", {
      layoutShowControls: false,
      viewportShowExpand: false,
      collapseLeftPanel: true,
      layoutShowSequence: true,
    });

    minimizedViewer.current?.loadStructureFromData(
      apiResponseRef.current.data.minimized_pdb,
      "pdb"
    );
  };

  useEffect(() => {
    if (!pdbID) return;
    if (pdbID.length !== 4) return;
    setFetchingAssembly(true);
    http
      .post(
        "/protein/detectAssemblies",
        {
          pdb_id: pdbID,
        },
        {
          params: {
            pdb_id: pdbID.toLowerCase(),
          },
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res: any) => {
        setAssemblies(res.data);
        setInitialPdb(true);
        setFetchingAssembly(false);
      })
      .catch((error) => {
        setFetchingAssembly(false);
        setShowError(true);
        if (error.response) {
          if (error.response.status >= 500) {
            setErrorMsg(errorMessages.serverError);
          } else if (error.response.status >= 400) {
            setErrorMsg(errorMessages.clientError);
          } else setErrorMsg(errorMessages.genericError);
        } else if (error.request) {
          setErrorMsg(errorMessages.connectionError);
        } else {
          setErrorMsg(errorMessages.requestError);
        }
      });
  }, [pdbID]);

  useEffect(() => {
    if (initialPdb) {
      showInitialProtein();
    }
  }, [initialPdb]);

  useEffect(() => {
    if (finalpdb) {
      showPreparedProtein();
      showMinimizedProtein();
    }
  }, [finalpdb]);

  const [tabValue, setTabValue] = useState("0");

  const handleSelect = (event: SelectChangeEvent) => {
    setAssembly(event.target.value as string);
  };

  useEffect(() => {
    ////console.log("inside useeffect")
    showInitialProtein(true);
  }, [assembly]);

  const handleExport = () => {
    setExportMsg("File export will begin shortly");
    http
      .get("/protein/prepare/export", {
        params: {
          pdb_id: apiResponseRef.current.data.pdb_id as string,
          dir_id: apiResponseRef.current.data.dir_id as string,
        },
        responseType: "arraybuffer",
      })
      .then(async (response: any) => {
        //     console.log(response);
        const blob = new Blob([response.data], { type: "application/zip" });
        saveAs(blob, `${pdbID}_prepared.zip`);
        setExportMsg("Protein file exported successfully");
      })
      .catch((error: any) => {
        console.error("Error during export:", error);

        setExportMsg("Error exporting file");
      });
  };

  const onBetaClick = () => {
    setBetaOpen(true);
  };

  const handleSubmit = async () => {
    setExportMsg(null);
    if (!isValidPhValue || !isValidsteps) return;
    if (!initialPdb) return;
    setRequestInProgress(true);
    setFinalPdb(false);
    ////console.log(SERVER_URL);

    http
      .post("/protein/prepare", {
        pdb_id: pdbID,
        assembly: assembly,
        pHValue: phValue ? phValue : GLOBAL_VARS.DEFAULT_PH,
        minimizationSteps: minSteps ? minSteps : 100,
        isAddMissingResidue: missingResidue,
        isAddDisulphideBonds: disulphideBonds,
        isRemoveHETAtoms: removeHET,
        isCapTerminal: addCapTerminal,
        jobDir: null,
      })
      .then(async (response: any) => {
        setRequestInProgress(false);
        //   console.log(response);
        if (response.data.Status === "Failed") {
          setShowError(true);
          setErrorMsg(response.data.Response);
          return;
        }
        apiResponseRef.current = response;
        setFinalPdb(true);
      })
      .catch((error) => {
        setRequestInProgress(false);
        setShowError(true);
        setErrorMsg(errorMessages.submitInputError);
      });
    // const response = await axios.post(
    //   "http://gamma.knowdisdata.com:9098/protein/prepare",
    //   {
    //     pdb_id: pdbID,
    //     assembly: assembly,
    //     isAddDisulphideBonds: disulphideBonds,
    //     isAddMissingResidue: missingResidue,
    //     minimizationSteps: minSteps ? minSteps : 100,
    //     pHValue: phValue ? phValue : 7,
    //     isRemoveHETAtoms: removeHET,
    //     isCapTerminal: addCapTerminal,
    //     jobDir: null,
    //   },
    //   {
    //     headers: {
    //       accept: "application/json",
    //       "Content-Type": "application/json",
    //     },
    //   }
    // );
    // //console.log(response);
    // setRequestInProgress(false);
    // apiResponseRef.current = response;
    // setFinalPdb(true);
  };
  const chipTabs = [
    {
      id: "0",
      label: "description",
      value: (
        <>
          <Typography mb={3}>
            Protein Prep is an AI-enabled protein preparation platform for all
            applications, including modeling, docking, and MD simulations. It
            prepares single- or multiple-chains of a protein in apo or holo
            state, with or without water, with a single click. Once the user
            submits the name of the PDB or uploads a PDB, the Protein Prep
            module automatically performs restraint and energy minimization, and
            protein preparation (adds missing residues, adds hydrogens, and
            protonates at the user-specified pH or the default physiological
            pH). It also prepares and protonates ligands at the physiological pH
            of 7.0 +/- 2 (or at a user-specified pH).
          </Typography>
          <Box
            component="img"
            src="/images/dashcard-images/protein_prep1.png"
            alt="Protein Prep Visual"
            sx={{
              width: "50%",
              height: "auto",
              mt: 4,
              mb: 2,
              mx: "auto",
              display: "block",
              p: 2,
            }}
          />
        </>
      ),
    },
    {
      id: "1",
      label: "References",
      value: (
        <>
          <Grid item container direction={"column"} mt={3}>
            {/* Add more references here */}
            <Typography variant={"body1"} mb={2}>
              [1] Eastman, Peter, et al. "OpenMM 7: Rapid development of high
              performance algorithms for molecular dynamics."
              <i>PLoS computational biology</i> 13.7 (2017): e1005659.
            </Typography>
            <Typography variant={"body1"} mb={2}>
              [2] Chapman, Brad, and Jeffrey Chang. "Biopython: Python tools for
              computational biology."<i> ACM Sigbio Newsletter</i> 20.2 (2000):
              15-19.
            </Typography>
            <Typography variant={"body1"} mb={2}>
              [3] O'Boyle, Noel M., et al. "Open Babel: An open chemical
              toolbox." <i>Journal of cheminformatics</i> 3.1 (2011): 1-14.
            </Typography>
            <Typography variant={"body1"} mb={2}>
              [4] Kunzmann, Patrick, and Kay Hamacher. "Biotite: a unifying open
              source computational biology framework in Python."{" "}
              <i>BMC bioinformatics</i> 19 (2018): 1-8.
            </Typography>
            <Typography variant={"body1"} mb={2}>
              [5] Olsson, Mats HM, et al. "PROPKA3: consistent treatment of
              internal and surface residues in empirical p K a predictions."{" "}
              <i>Journal of chemical theory and computation</i> 7.2 (2011):
              525-537.
            </Typography>
            <Typography variant={"body1"} mb={2}>
              [6] Rose, Peter W., et al. "The RCSB Protein Data Bank: new
              resources for research and education."{" "}
              <i>Nucleic acids research</i> 41.D1 (2012): D475-D482.
            </Typography>
          </Grid>
        </>
      ),
    },
  ];

  const isValidPhValue =
    phValue >= GLOBAL_VARS.MIN_PH && phValue <= GLOBAL_VARS.MAX_PH;
  const isValidsteps =
    minSteps >= GLOBAL_VARS.MIN_MINIMIZATION_STEPS &&
    minSteps <= GLOBAL_VARS.MAX_MINIMIZATION_STEPS;

  return (
    <div>
      <Snackbar
        open={showError}
        autoHideDuration={9000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <Box py={2}>
        {/* <Button onClick={handleDownloadSample}>Download Sample file</Button> */}
        {finalpdb && exportMsg && (
          <Alert
            onClose={() => {
              setExportMsg(null);
            }}
            sx={{ mt: 2, px: 5 }}
            severity={
              exportMsg === "Error exporting file" ? "error" : "success"
            }
          >
            {exportMsg}
          </Alert>
        )}
        <Grid container>
          <Grid
            item
            xs={12}
            md={finalpdb ? 6 : 8}
            sx={{ pt: 2, width: "100%" }}
            p={2.5}
          >
            {initialPdb ? (
              <>
                <Card>
                  <CardContent sx={{ pt: 1, mb: 2 }}>
                    <Typography py={1} variant="h5">
                      {" "}
                      Initial Protein{" "}
                    </Typography>
                    <Typography py={1} variant="h6">
                      PDB : {pdbID}
                    </Typography>
                    <div id="viewer-1" className="section-1"></div>
                  </CardContent>
                </Card>
                {finalpdb && (
                  <Card sx={{ mt: 2 }}>
                    <CardContent sx={{ pt: 1, mb: 2 }}>
                      <Typography py={1} variant="h5">
                        {" "}
                        Prepared Protein{" "}
                      </Typography>
                      <Typography py={1} variant="h6">
                        Assembly ID : {assembly}
                      </Typography>
                      <div id="viewer-2" className="section-1"></div>
                    </CardContent>
                  </Card>
                )}
              </>
            ) : (
              <Card>
                <CardContent sx={{ p: 2, pt: 0 }}>
                  <Grid item>
                    <TabContext value={tabValue}>
                      <Box
                        sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}
                      >
                        <TabList
                          value={tabValue}
                          onChange={handleTabChange}
                          variant="scrollable"
                          scrollButtons="auto"
                        >
                          {chipTabs.map((tab, index) => (
                            <Tab key={index} label={tab.label} value={tab.id} />
                          ))}
                        </TabList>
                      </Box>

                      <Grid item sx={{ px: 0 }}>
                        {chipTabs.map((tab, index) => (
                          <TabPanel key={index} value={tab.id}>
                            <Typography>{tab.value}</Typography>
                          </TabPanel>
                        ))}
                      </Grid>
                    </TabContext>
                  </Grid>
                  {/* /content */}
                </CardContent>
              </Card>
            )}
          </Grid>

          <Grid item xs={12} md={finalpdb ? 6 : 4} pt={2} pr={2}>
            {finalpdb ? (
              <>
                <Grid sx={{ justifyContent: "center", display: "flex", mb: 2 }}>
                  <Button
                    size="large"
                    sx={{ px: 3, py: 2, fontSize: 30, width: "100%" }}
                    onClick={handleExport}
                    variant="contained"
                  >
                    Export Proteins
                  </Button>
                </Grid>
                <Card>
                  <CardContent sx={{ py: 1, mb: 2 }}>
                    {/* <Grid
                    container
                    direction={"row"}
                    sx={{ justifyContent: "space-between" }}
                    py={1}
                  > */}
                    <Typography variant="h5"> Minimized Protein </Typography>
                    <Typography py={1} variant="h6">
                      Assembly ID : {assembly}
                    </Typography>

                    {/* </Grid> */}

                    <div id="viewer-3" className="section-1"></div>
                  </CardContent>
                </Card>
              </>
            ) : (
              <Card>
                <CardContent>
                  {runInProd && (
                    <Grid container direction="column" className="beta-anchor">
                      <Grid item xs={12} sx={{ mt: 5 }}>
                        <Typography>
                          This is a Premium feature. Try with
                          <Button variant="contained" onClick={onBetaClick}>
                            BETA ACCESS
                          </Button>
                          <BetaModal
                            open={betaOpen}
                            openHandler={setBetaOpen}
                          ></BetaModal>
                        </Typography>
                      </Grid>
                    </Grid>
                  )}

                  <FormControl
                    fullWidth
                    className={RUNTIME === "PROD" ? "blurry-bg" : ""}
                  >
                    <Stack direction="column" spacing={1.5}>
                      <Typography
                        display={"inline-block"}
                        variant={"h6"}
                        sx={{ color: "grey.900" }}
                        ml={1}
                        fontWeight={"600"}
                      >
                        {"Input"}
                      </Typography>
                      <TextField
                        required
                        label="Enter a PDB ID"
                        placeholder="PDB ID eg : 3GZO"
                        value={pdbID}
                        onChange={(e) => setPDBID(e.target.value)}
                        fullWidth
                      />
                      <Grid item>
                        <Button
                          variant="text"
                          id="sample"
                          name="id"
                          value="3zgo"
                          onClick={() => {
                            setPDBID("3zgo");
                          }}
                        >
                          Example: 3ZGO
                        </Button>
                      </Grid>
                    </Stack>
                    <Stack py={2}>
                      <Grid container direction="column" spacing={2}>
                        <Grid item sx={{ width: 1 }}>
                          <FormControl sx={{ width: 1 }}>
                            <InputLabel id="assembly-select-label">
                              Assembly
                            </InputLabel>
                            <Select
                              disabled={assemblies?.length}
                              value={assembly}
                              label="Assembly"
                              onChange={handleSelect}
                            >
                              {Object?.keys(assemblies ? assemblies : []).map(
                                (i) => {
                                  return (
                                    <MenuItem key={i} value={i}>
                                      {assemblies[i]}
                                    </MenuItem>
                                  );
                                }
                              )}
                            </Select>
                            {fetchingAssembly ? <LinearProgress /> : ""}
                          </FormControl>
                        </Grid>
                        <Grid item>
                          <FormControl fullWidth error={!isValidPhValue}>
                            <TextField
                              label="Enter pH Value"
                              placeholder="Enter pH Value"
                              fullWidth
                              type="number"
                              value={phValue}
                              inputProps={{
                                min: GLOBAL_VARS.MIN_PH,
                                max: GLOBAL_VARS.MAX_PH,
                                step: "any",
                              }}
                              onChange={(e: any) => {
                                setPhValue(e.target.value);
                              }}
                            />
                            {!isValidPhValue && (
                              <FormHelperText>
                                Error: pH value should be between 0 and 14.
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item>
                          <FormControl fullWidth error={!isValidsteps}>
                            <TextField
                              label="Energy Minimisation Step"
                              placeholder="Energy Minimisation Steps"
                              fullWidth
                              type="number"
                              value={minSteps}
                              inputProps={{
                                min: GLOBAL_VARS.MIN_MINIMIZATION_STEPS,
                                max: GLOBAL_VARS.MAX_MINIMIZATION_STEPS,
                              }}
                              onChange={(e: any) => {
                                setMinSteps(e.target.value);
                              }}
                            />
                            {!isValidsteps && (
                              <FormHelperText>
                                Error: Steps should be between 50 and 10000.
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item>
                          <Grid container spacing={1}>
                            <Grid item>
                              <FormControlLabel
                                label={
                                  <Typography variant="body1">
                                    Add Missing Residue:
                                  </Typography>
                                }
                                labelPlacement="start"
                                control={
                                  <Checkbox
                                    checked={missingResidue}
                                    onChange={(e: any) =>
                                      setMissingResidue(e.target.checked)
                                    }
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                }
                              />
                            </Grid>
                            <Grid item>
                              <FormControlLabel
                                label={
                                  <Typography variant="body1">
                                    Add Disulphide Bonds:
                                  </Typography>
                                }
                                labelPlacement="start"
                                control={
                                  <Checkbox
                                    checked={disulphideBonds}
                                    onChange={(e: any) =>
                                      setDisulphideBonds(e.target.checked)
                                    }
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid container spacing={1}>
                            <Grid item>
                              <FormControlLabel
                                label={
                                  <Typography variant="body1">
                                    Remove HET atoms:
                                  </Typography>
                                }
                                labelPlacement="start"
                                control={
                                  <Checkbox
                                    checked={removeHET}
                                    onChange={(e: any) =>
                                      setRemoveHET(e.target.checked)
                                    }
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                }
                              />
                            </Grid>
                            <Grid item>
                              <FormControlLabel
                                label={
                                  <Typography variant="body1">
                                    Add Cap Terminals:
                                  </Typography>
                                }
                                labelPlacement="start"
                                control={
                                  <Checkbox
                                    checked={addCapTerminal}
                                    onChange={(e: any) =>
                                      setAddCapTerminal(e.target.checked)
                                    }
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack direction="column" spacing={1.5} sx={{ mt: 3 }}>
                      <Button
                        variant={"contained"}
                        color={"primary"}
                        fullWidth
                        onClick={handleSubmit}
                      >
                        {"Submit"}
                      </Button>
                    </Stack>
                  </FormControl>
                  <IndefiniteLoader state={requestInProgress} />
                </CardContent>
              </Card>
            )}
          </Grid>
        </Grid>
      </Box>

      {/* <Grid container spacing={2}>
          <Grid item xs={6} md={6}>
            <Card>
              <CardContent sx={{ pt: 2 }}>
                <Typography variant="h5"> Initial Protein </Typography>
                <div id="viewer-1" className="section-1"></div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} md={6}>
            <Card>
              <CardContent sx={{ pt: 2 }}>
                <Typography variant="h5"> Prepared Protein </Typography>

                <div id="viewer-2" className="section-1"></div>
              </CardContent>
            </Card>
          </Grid>
        </Grid> */}
    </div>
  );
}

export default ProtienPreprocess;
