import { PluginUIContext } from "molstar/lib/mol-plugin-ui/context";
import { PluginUIComponent } from "molstar/lib/mol-plugin-ui/base";
import {
  DefaultPluginUISpec,
  PluginUISpec,
} from "molstar/lib//mol-plugin-ui/spec";
import { ObjectKeys } from "molstar/lib/mol-util/type-helpers";
import { G3DFormat, G3dProvider } from "molstar/lib/extensions/g3d/format";
import {
  PluginStateObject,
  PluginStateObject as PSO,
} from "molstar/lib/mol-plugin-state/objects";
import { DataFormatProvider } from "molstar/lib/mol-plugin-state/formats/provider";
import { PluginLayoutControlsDisplay } from "molstar/lib/mol-plugin/layout";
import { createPluginUI } from "molstar/lib/mol-plugin-ui/react18";
import {
  PresetStructureRepresentations,
  StructureRepresentationPresetProvider,
} from "molstar/lib/mol-plugin-state/builder/structure/representation-preset";
import { DownloadStructure } from "molstar/lib/mol-plugin-state/actions/structure";
import { PluginConfig } from "molstar/lib/mol-plugin/config";
import { Backgrounds } from "molstar/lib/extensions/backgrounds";

import {
  QualityAssessmentPLDDTPreset,
  QualityAssessmentQmeanPreset,
} from "molstar/lib/extensions/model-archive/quality-assessment/behavior";
import { QualityAssessment } from "molstar/lib/extensions/model-archive/quality-assessment/prop";
import { PluginSpec } from "molstar/lib/mol-plugin/spec";
import { BuiltInTrajectoryFormat } from "molstar/lib/mol-plugin-state/formats/trajectory";
import { StateBuilder, StateObject } from "molstar/lib/mol-state";
import { Asset } from "molstar/lib/mol-util/assets";
import { StateObjectRef } from "molstar/lib/mol-state";

import "molstar/build/viewer/molstar.css";
import "../../css/viewer.css";
import "molstar/lib/mol-util/polyfill";
import { PluginCommands } from "molstar/lib/mol-plugin/commands";
import {
  LoadParams,
  ModelInfo,
  RepresentationStyleHolder,
  SupportedFormats,
} from "../../utils/helpers";
import { StateElements } from "../../utils/helpers";
import { StateTransforms } from "molstar/lib/mol-plugin-state/transforms";
import { createStructureRepresentationParams } from "molstar/lib/mol-plugin-state/helpers/structure-representation-params";
import { RxEventHelper } from "molstar/lib/mol-util/rx-event-helper";
import { Color } from "molstar/lib/mol-util/color";
import { StateObjectSelector } from "molstar/lib/mol-state";
import { TrajectoryFromModelAndCoordinates } from "molstar/lib/mol-plugin-state/transforms/model";
import { BuiltInTopologyFormat } from "molstar/lib/mol-plugin-state/formats/topology";
import { BuiltInCoordinatesFormat } from "molstar/lib/mol-plugin-state/formats/coordinates";
import { PresetTrajectoryHierarchy } from "molstar/lib/mol-plugin-state/builder/structure/hierarchy-preset";

const CustomFormats = [["g3d", G3dProvider] as const];

// TODO(bhuthesh) - add more extensions
const Extensions = {
  backgrounds: PluginSpec.Behavior(Backgrounds),
  g3d: PluginSpec.Behavior(G3DFormat),
};

const DefaultViewerOptions = {
  extensions: ObjectKeys(Extensions),
  customFormats: CustomFormats as [string, DataFormatProvider][],

  layoutIsExpanded: false,
  layoutShowControls: true,
  layoutShowRemoteState: true,
  layoutControlsDisplay: "reactive" as PluginLayoutControlsDisplay,
  layoutShowSequence: true,
  layoutShowLog: false,
  layoutShowLeftPanel: true,

  // viewportShowExpand: PluginConfig.Viewport.ShowExpand.defaultValue,
  viewportShowControls: PluginConfig.Viewport.ShowControls.defaultValue,
  // viewportShowSettings: PluginConfig.Viewport.ShowSettings.defaultValue,
  // viewportShowSelectionMode: PluginConfig.Viewport.ShowSelectionMode.defaultValue,
  viewportShowAnimation: PluginConfig.Viewport.ShowAnimation.defaultValue,
  viewportShowTrajectoryControls: true,
  // PluginConfig.Viewport.ShowTrajectoryControls.defaultValue,

  viewportShowExpand: false,
  //viewportShowSelectionMode: true,
  // viewportShowAnimation: true,
  // viewportShowControls: true,
  viewportShowSettings: true,
  collapseLeftPanel: true,
  collapseRightPanel: true,
  viewportShowSelectionMode:
    PluginConfig.Viewport.ShowSelectionMode.defaultValue,
  pdbProvider: PluginConfig.Download.DefaultPdbProvider.defaultValue,
  emdbProvider: PluginConfig.Download.DefaultEmdbProvider.defaultValue,

  disableAntialiasing: PluginConfig.General.DisableAntialiasing.defaultValue,
  //viewportShowTrajectoryControls: PluginConfig.Viewport.ShowTrajectoryControls.defaultValue,
  pixelScale: PluginConfig.General.PixelScale.defaultValue,
  pickScale: PluginConfig.General.PickScale.defaultValue,

  pickPadding: PluginConfig.General.PickPadding.defaultValue,
  preferWebgl1: PluginConfig.General.PreferWebGl1.defaultValue,

  enableWboit: PluginConfig.General.EnableWboit.defaultValue,
  enableDpoit: PluginConfig.General.EnableDpoit.defaultValue,

  allowMajorPerformanceCaveat:
    PluginConfig.General.AllowMajorPerformanceCaveat.defaultValue,
  powerPreference: PluginConfig.General.PowerPreference.defaultValue,

  pluginStateServer: PluginConfig.State.DefaultServer.defaultValue,

  // custom colors
  customColors: [0x00ff00, 0x0000ff],
  innerWidth: 200,
  outerWidth: 200,
};

type ViewerOptions = typeof DefaultViewerOptions;

class Viewer {
  plugin: PluginUIContext;

  private _ev = RxEventHelper.create();

  readonly events = {
    modelInfo: this._ev<ModelInfo>(),
  };

  async init(
    elementOrId: string | HTMLElement,
    options: Partial<ViewerOptions> = {}
  ) {
    const definedOptions = {} as any;

    // collect all defined options
    for (const p of Object.keys(options) as (keyof ViewerOptions)[]) {
      if (options[p] !== void 0) definedOptions[p] = options[p];
    }

    const o: ViewerOptions = { ...DefaultViewerOptions, ...definedOptions };
    const defaultSpec = DefaultPluginUISpec();

    const spec: PluginUISpec = {
      actions: defaultSpec.actions,

      behaviors: [
        ...defaultSpec.behaviors,
        ...o.extensions.map((e) => Extensions[e]),
      ],

      customFormats: o?.customFormats,
      animations: [...(defaultSpec.animations || [])],
      layout: {
        initial: {
          isExpanded: o.layoutIsExpanded,
          showControls: o.layoutShowControls,
          controlsDisplay: o.layoutControlsDisplay,
          regionState: {
            bottom: "full",
            left: o.collapseLeftPanel ? "collapsed" : "full",
            right: o.collapseRightPanel ? "hidden" : "full",
            top: "full",
          },
        },
      },

      components: {
        ...defaultSpec.components,
        controls: {
          ...defaultSpec.components?.controls,
          top: o.layoutShowSequence ? undefined : "none",
          bottom: o.layoutShowLog ? undefined : "none",
          left: o.layoutShowLeftPanel ? undefined : "none",
        },
        remoteState: o.layoutShowRemoteState ? "default" : "none",
      },

      config: [
        [PluginConfig.Download.DefaultPdbProvider, o.pdbProvider],
        [PluginConfig.Download.DefaultEmdbProvider, o.emdbProvider],

        [PluginConfig.General.DisableAntialiasing, o.disableAntialiasing],
        [PluginConfig.General.PixelScale, o.pixelScale],
        [PluginConfig.General.PickScale, o.pickScale],
        [PluginConfig.General.PickPadding, o.pickPadding],
        [PluginConfig.General.EnableWboit, o.enableWboit],
        [PluginConfig.General.EnableDpoit, o.enableDpoit],
        [PluginConfig.General.PreferWebGl1, o.preferWebgl1],
        [
          PluginConfig.General.AllowMajorPerformanceCaveat,
          o.allowMajorPerformanceCaveat,
        ],
        [PluginConfig.General.PowerPreference, o.powerPreference],

        [PluginConfig.Viewport.ShowExpand, o.viewportShowExpand],
        [PluginConfig.Viewport.ShowControls, o.viewportShowControls],
        [PluginConfig.Viewport.ShowSettings, o.viewportShowSettings],
        [PluginConfig.Viewport.ShowSelectionMode, o.viewportShowSelectionMode],
        [PluginConfig.Viewport.ShowAnimation, o.viewportShowAnimation],
        [
          PluginConfig.Viewport.ShowTrajectoryControls,
          o.viewportShowTrajectoryControls,
        ],
        [PluginConfig.State.DefaultServer, o.pluginStateServer],
        [PluginConfig.State.CurrentServer, o.pluginStateServer],

        // DefaultRepresentationPreset: item<string>('structure.default-representation-preset', 'auto'),
        //DefaultRepresentationPresetParams: item<StructureRepresentationPresetProvider.CommonParams>('structure.default-representation-preset-params', { }),
        [
          PluginConfig.Structure.DefaultRepresentationPreset,
          ViewerAutoPreset.id,
        ],
      ],
    };

    const element =
      typeof elementOrId === "string"
        ? document.getElementById(elementOrId)
        : elementOrId;

    if (!element)
      throw new Error(`Could not get element with id '${elementOrId}'`);

    this.plugin = await createPluginUI(element, spec, {
      onBeforeUIRender: (plugin) => {
        // the preset needs to be added before the UI renders otherwise
        // "Download Structure" wont be able to pick it up
        plugin.builders.structure.representation.registerPreset(
          ViewerAutoPreset
        );
      },
    });

    // TODO(coloring):bhuthesh@
    // const customColoring = createProteopediaCustomTheme((options && options.customColorList) || []);

    //     this.plugin.representation.structure.themes.colorThemeRegistry.add(customColoring);
    //     this.plugin.representation.structure.themes.colorThemeRegistry.add(EvolutionaryConservation.colorThemeProvider!);
    //     this.plugin.managers.lociLabels.addProvider(EvolutionaryConservation.labelProvider!);
    //     this.plugin.customModelProperties.register(EvolutionaryConservation.propertyProvider, true);
    // return new Viewer(this.plugin);
  }

  async loadTrajectory(params: LoadTrajectoryParams) {
    const plugin = this.plugin;

    let model: StateObjectSelector;

    if (
      params.model.kind === "model-data" ||
      params.model.kind === "model-url"
    ) {
      const data =
        params.model.kind === "model-data"
          ? await plugin.builders.data.rawData({
              data: params.model.data,
              label: params.modelLabel,
            })
          : await plugin.builders.data.download({
              url: params.model.url,
              isBinary: params.model.isBinary,
              label: params.modelLabel,
            });

      const trajectory = await plugin.builders.structure.parseTrajectory(
        data,
        params.model.format ?? "mmcif"
      );
      model = await plugin.builders.structure.createModel(trajectory);
      let a = await plugin.builders.structure.tryCreateUnitcell(
        model,
        params,
        { isHidden: false }
      );
      // console.log(a);
      // console.log("m2 : ", model);
    } else {
      const data =
        params.model.kind === "topology-data"
          ? await plugin.builders.data.rawData({
              data: params.model.data,
              label: params.modelLabel,
            })
          : await plugin.builders.data.download({
              url: params.model.url,
              isBinary: params.model.isBinary,
              label: params.modelLabel,
            });

      const provider = plugin.dataFormats.get(params.model.format);
      model = await provider!.parse(plugin, data);
    }

    const data =
      params.coordinates.kind === "coordinates-data"
        ? await plugin.builders.data.rawData({
            data: params.coordinates.data,
            label: params.coordinatesLabel,
          })
        : await plugin.builders.data.download({
            url: params.coordinates.url,
            isBinary: params.coordinates.isBinary,
            label: params.coordinatesLabel,
          });

    const provider = plugin.dataFormats.get(params.coordinates.format);
    const coords = await provider!.parse(plugin, data);

    const trajectory = await plugin
      .build()
      .toRoot()
      .apply(
        TrajectoryFromModelAndCoordinates,
        {
          modelRef: model.ref,
          coordinatesRef: coords.ref,
        },
        { dependsOn: [model.ref, coords.ref] }
      )
      .commit();

    const preset = await plugin.builders.structure.hierarchy.applyPreset(
      trajectory,
      "default"
    );

    return { model, coords, preset };
  }

  loadStructureFromUrl(
    url: string,
    format: BuiltInTrajectoryFormat = "mmcif",
    isBinary = false,
    options?: LoadStructureOptions & { label?: string }
  ) {
    const params = DownloadStructure.createDefaultParams(
      this.plugin.state.data.root.obj!,
      this.plugin
    );
    return this.plugin.runTask(
      this.plugin.state.data.applyAction(DownloadStructure, {
        source: {
          name: "url",
          params: {
            url: Asset.Url(url),
            format: format as any,
            isBinary,
            label: options?.label,
            options: {
              ...params.source.params.options,
              representationParams: options?.representationParams as any,
            },
          },
        },
      })
    );
  }

  async loadAllModelsOrAssemblyFromUrl(
    url: string,
    format: BuiltInTrajectoryFormat = "mmcif",
    isBinary = false,
    options?: LoadStructureOptions
  ) {
    const plugin = this.plugin;

    const data = await plugin.builders.data.download(
      { url, isBinary },
      { state: { isGhost: true } }
    );
    const trajectory = await plugin.builders.structure.parseTrajectory(
      data,
      format
    );

    await this.plugin.builders.structure.hierarchy.applyPreset(
      trajectory,
      "all-models",
      {
        useDefaultIfSingleModel: true,
        representationPresetParams: options?.representationParams,
      }
    );
  }


  async loadStructureFromData(
    data: string | number[],
    format: BuiltInTrajectoryFormat,
    options?: { dataLabel?: string },
    oldref?: StateObjectRef
  ) {
    if (oldref) {
      const update = this.plugin.build();
      update.delete(oldref);
      update.commit();
    }
    const _data = await this.plugin.builders.data.rawData({
      data,
      label: options?.dataLabel,
    });
    const trajectory = await this.plugin.builders.structure.parseTrajectory(
      _data,
      format
    );
    await this.plugin.builders.structure.hierarchy.applyPreset(
      trajectory,
      "default"
    );
    return _data;
  }

  async loadPdb(
    pdb: string,
    options?: LoadStructureOptions,
    style?: RepresentationStyleHolder
  ) {
    const structureUrl = `https://files.rcsb.org/download/${pdb}.pdb`;

    if (!structureUrl) return;
    const data = await this.plugin.builders.data.download(
      { url: structureUrl },
      { state: { isGhost: true } }
    );
    const traj = await this.plugin.builders.structure.parseTrajectory(
      data,
      "pdb"
    );
    await this.plugin.builders.structure.hierarchy.applyPreset(traj, "default");
    var representationStyle = {
      //sequence: { coloring: "custom-color" }, // or just { }
      hetGroups: { kind: "ball-and-stick" }, // or 'spacefill
      water: { hide: true },
      snfg3d: { hide: false },
    };
    await this.updateStyle({ hetGroups: { kind: "ball-and-stick" } }, true);
    await this.updateStyle({ water: { hide: false } }, true);
  }

  private emptyLoadedParams: LoadParams = {
    url: "",
    format: "cif",
    isBinary: false,
    assemblyId: "",
  };
  private loadedParams: LoadParams = {
    url: "",
    format: "cif",
    isBinary: false,
    assemblyId: "",
  };

  async load({
    url,
    format = "cif",
    assemblyId = "",
    isBinary = false,
    representationStyle,
    assemblychange = false,
  }: LoadParams & { assemblychange?: boolean }) {
    let loadType: "full" | "update" = "full";

    const state = this.plugin.state.data;

    if (this.loadedParams.url !== url || this.loadedParams.format !== format) {
      loadType = "full";
    } else if (this.loadedParams.url === url) {
      if (state.select(StateElements.Assembly).length > 0) loadType = "update";
    }

    if (loadType === "full") {
      await PluginCommands.State.RemoveObject(this.plugin, {
        state,
        ref: state.tree.root.ref,
      });
      const modelTree = this.model(
        this.download(state.build().toRoot(), url, isBinary),
        format
      );
      await this.applyState(modelTree);
      const info = await this.doInfo(true);
      const asmId =
        (assemblyId === "preferred" && info && info.preferredAssemblyId) ||
        assemblyId;
      const structureTree = this.structure(asmId);
      await this.applyState(structureTree);
    } else {
      const tree = state.build();
      const info = await this.doInfo(true);
      const asmId =
        (assemblyId === "preferred" && info && info.preferredAssemblyId) ||
        assemblyId;
      const props = {
        type: assemblyId
          ? {
              name: "assembly" as const,
              params: { id: asmId },
            }
          : {
              name: "model" as const,
              params: {},
            },
      };
      tree
        .to(StateElements.Assembly)
        .update(StateTransforms.Model.StructureFromModel, (p) => ({
          ...p,
          ...props,
        }));
      await this.applyState(tree);
    }

    await this.updateStyle(representationStyle);

    this.loadedParams = { url, format, assemblyId };
  }

  // styling related functions
  get state() {
    return this.plugin.state.data;
  }

  private download(
    b: StateBuilder.To<PSO.Root>,
    url: string,
    isBinary: boolean
  ) {
    return b.apply(StateTransforms.Data.Download, {
      url: Asset.Url(url),
      isBinary,
    });
  }

  private model(
    b: StateBuilder.To<PSO.Data.Binary | PSO.Data.String>,
    format: SupportedFormats
  ) {
    const parsed =
      format === "cif"
        ? b
            .apply(StateTransforms.Data.ParseCif)
            .apply(StateTransforms.Model.TrajectoryFromMmCif)
        : b.apply(StateTransforms.Model.TrajectoryFromPDB);

    return parsed.apply(
      StateTransforms.Model.ModelFromTrajectory,
      { modelIndex: 0 },
      { ref: StateElements.Model }
    );
  }

  private structure(assemblyId: string) {
    const model = this.state.build().to(StateElements.Model);
    const props = {
      type: assemblyId
        ? {
            name: "assembly" as const,
            params: { id: assemblyId },
          }
        : {
            name: "model" as const,
            params: {},
          },
    };

    const s = model.apply(StateTransforms.Model.StructureFromModel, props, {
      ref: StateElements.Assembly,
    });

    s.apply(
      StateTransforms.Model.StructureComplexElement,
      { type: "atomic-sequence" },
      { ref: StateElements.Sequence }
    );
    s.apply(
      StateTransforms.Model.StructureComplexElement,
      { type: "atomic-het" },
      { ref: StateElements.Het }
    );
    s.apply(
      StateTransforms.Model.StructureComplexElement,
      { type: "water" },
      { ref: StateElements.Water }
    );

    return s;
  }

  setBackground(color: number) {
    if (!this.plugin.canvas3d) return;
    const renderer = this.plugin.canvas3d.props.renderer;
    PluginCommands.Canvas3D.SetSettings(this.plugin, {
      settings: { renderer: { ...renderer, backgroundColor: Color(color) } },
    });
  }

  async updateStyle(style?: RepresentationStyleHolder, partial?: boolean) {
    const tree = this.visual(style, partial);
    if (!tree) return;
    await PluginCommands.State.Update(this.plugin, {
      state: this.plugin.state.data,
      tree,
    });
  }

  private visual(_style?: RepresentationStyleHolder, partial?: boolean) {
    const structure = this.getObj<PluginStateObject.Molecule.Structure>(
      StateElements.Assembly
    );
    if (!structure) return;

    const style = _style || {};

    const update = this.state.build();

    if (!partial || (partial && style.sequence)) {
      const root = update.to(StateElements.Sequence);
      if (style.sequence && style.sequence.hide) {
        root.delete(StateElements.SequenceVisual);
      } else {
        root.applyOrUpdate(
          StateElements.SequenceVisual,
          StateTransforms.Representation.StructureRepresentation3D,
          createStructureRepresentationParams(this.plugin, structure, {
            type: (style.sequence && style.sequence.kind) || "cartoon",
            color: (style.sequence && style.sequence.coloring) || "unit-index",
          })
        );
      }
    }

    if (!partial || (partial && style.hetGroups)) {
      const root = update.to(StateElements.Het);
      if (style.hetGroups && style.hetGroups.hide) {
        root.delete(StateElements.HetVisual);
      } else {
        if (style.hetGroups && style.hetGroups.hide) {
          root.delete(StateElements.HetVisual);
        } else {
          root.applyOrUpdate(
            StateElements.HetVisual,
            StateTransforms.Representation.StructureRepresentation3D,
            createStructureRepresentationParams(this.plugin, structure, {
              type:
                (style.hetGroups && style.hetGroups.kind) || "ball-and-stick",
              color: style.hetGroups.coloring,
            })
          );
        }
      }
    }

    if (!partial || (partial && style.snfg3d)) {
      const root = update.to(StateElements.Het);
      if (style.hetGroups && style.hetGroups.hide) {
        root.delete(StateElements.HetVisual);
      } else {
        if (style.snfg3d && style.snfg3d.hide) {
          root.delete(StateElements.Het3DSNFG);
        } else {
          root.applyOrUpdate(
            StateElements.Het3DSNFG,
            StateTransforms.Representation.StructureRepresentation3D,
            createStructureRepresentationParams(this.plugin, structure, {
              type: "carbohydrate",
            })
          );
        }
      }
    }

    if (!partial || (partial && style.water)) {
      const root = update.to(StateElements.Water);
      if (style.water && style.water.hide) {
        root.delete(StateElements.WaterVisual);
      } else {
        root.applyOrUpdate(
          StateElements.WaterVisual,
          StateTransforms.Representation.StructureRepresentation3D,
          createStructureRepresentationParams(this.plugin, structure, {
            type: (style.water && style.water.kind) || "ball-and-stick",
            typeParams: { alpha: 0.51 },
            color: style.water.coloring,
          })
        );
      }
    }

    return update;
  }

  private getObj<T extends StateObject>(ref: string): T["data"] {
    const state = this.state;
    const cell = state.select(ref)[0];
    if (!cell || !cell.obj) return void 0;
    return (cell.obj as T).data;
  }

  private async doInfo(checkPreferredAssembly: boolean) {
    const model = this.getObj<PluginStateObject.Molecule.Model>("model");
    if (!model) return;

    const info = await ModelInfo.get(
      this.plugin,
      model,
      checkPreferredAssembly
    );
    this.events.modelInfo.next(info);
    return info;
  }

  private applyState(tree: StateBuilder) {
    return PluginCommands.State.Update(this.plugin, {
      state: this.plugin.state.data,
      tree,
    });
  }
}

export interface LoadStructureOptions {
  representationParams?: StructureRepresentationPresetProvider.CommonParams;
}

export interface LoadTrajectoryParams {
  model:
    | {
        kind: "model-url";
        url: string;
        format?: BuiltInTrajectoryFormat /* mmcif */;
        isBinary?: boolean;
      }
    | {
        kind: "model-data";
        data: string | number[] | ArrayBuffer | Uint8Array;
        format?: BuiltInTrajectoryFormat /* mmcif */;
      }
    | {
        kind: "topology-url";
        url: string;
        format: BuiltInTopologyFormat;
        isBinary?: boolean;
      }
    | {
        kind: "topology-data";
        data: string | number[] | ArrayBuffer | Uint8Array;
        format: BuiltInTopologyFormat;
      };
  modelLabel?: string;
  coordinates:
    | {
        kind: "coordinates-url";
        url: string;
        format: BuiltInCoordinatesFormat;
        isBinary?: boolean;
      }
    | {
        kind: "coordinates-data";
        data: string | number[] | ArrayBuffer | Uint8Array;
        format: BuiltInCoordinatesFormat;
      };
  coordinatesLabel?: string;
  preset?: keyof PresetTrajectoryHierarchy;
}

export const ViewerAutoPreset = StructureRepresentationPresetProvider({
  id: "preset-structure-representation-viewer-auto",
  display: {
    name: "Automatic (w/ Annotation)",
    group: "Annotation",
    description:
      "Show standard automatic representation but colored by quality assessment (if available in the model).",
  },
  isApplicable(a) {
    return (
      !!a.data.models.some((m) => QualityAssessment.isApplicable(m, "pLDDT")) ||
      !!a.data.models.some((m) => QualityAssessment.isApplicable(m, "qmean"))
    );
  },
  params: () => StructureRepresentationPresetProvider.CommonParams,
  async apply(ref, params, plugin) {
    const structureCell = StateObjectRef.resolveAndCheck(
      plugin.state.data,
      ref
    );
    const structure = structureCell?.obj?.data;
    if (!structureCell || !structure) return {};

    if (
      !!structure.models.some((m) => QualityAssessment.isApplicable(m, "pLDDT"))
    ) {
      return await QualityAssessmentPLDDTPreset.apply(ref, params, plugin);
    } else if (
      !!structure.models.some((m) => QualityAssessment.isApplicable(m, "qmean"))
    ) {
      return await QualityAssessmentQmeanPreset.apply(ref, params, plugin);
    } else {
      return await PresetStructureRepresentations.auto.apply(
        ref,
        params,
        plugin
      );
    }
  },
});

export default Viewer;
