import React, { useEffect } from "react";
import { useState } from "react";
import {
  DataGrid,
  GridCellParams,
  GridRenderCellParams,
  useGridApiRef,
} from "@mui/x-data-grid";

import Button from "@mui/material/Button";
import clsx from "clsx";
import {
  Box,
  Typography,
  Popover,
  Grid,
  Tooltip,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import JobDetails from "./JobDetails";
import JobArgs from "./JobArgs";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/Delete";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import http from "../../net/http-common";
import { useUserAuth } from "../../context";
import { JOB_SERVER_URL } from "../../config";
import SubTaskStatus from "./SubTaskStatus";

export interface JobsProps {
  jobs: any[];
  handleBackdrop?: (status: boolean) => void;
  disableDownload?: boolean;
  disableVisualize?: boolean;
  setDoLoadJobs?: React.Dispatch<React.SetStateAction<boolean>>;
  nextUrl?: string;
  setShowError?: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMsg?: React.Dispatch<any>;
  onSuccessfullJobSubmission?: (jobID: number) => void;
}

export function formatTimestamp(dateTimeString: any) {
  const date = new Date(dateTimeString);

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const formattedDate = date.toLocaleString("en-IN", {
    timeZone: userTimeZone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });

  return formattedDate;
}

function RenderResult(props: GridRenderCellParams<any, string>) {
  const { hasFocus, value } = props;
  // //console.log("result", value);
  return (
    <strong>
      <Button
        component="button"
        variant="contained"
        size="small"
        style={{ marginLeft: 16 }}
        // Remove button from tab sequence when cell does not have focus
        tabIndex={hasFocus ? 0 : -1}
        onKeyDown={(event: React.KeyboardEvent) => {
          if (event.key === " ") {
            // Prevent key navigation when focus is on button
            event.stopPropagation();
          }
        }}
      >
        Open
      </Button>
    </strong>
  );
}

function JobsTable(props: JobsProps) {
  const apiRef = useGridApiRef();

  const { user } = useUserAuth();

  const [deleteOrCancel, setDeleteOrCancel] = useState<boolean>(false);
  const [jobActionType, setJobActionType] = useState<string>("");
  const [jobToDelete, setJobToDelete] = useState<any>(null);

  const [openDialoag, setOpenDialog] = useState<boolean>(false);

  // function to open and close modal and set job info for deleting and canceling
  const handleModal = (isModelOpen: boolean, jobData?: any) => {
    if (isModelOpen) {
      setJobToDelete(jobData);
      setOpenDialog(true);
    } else if (!isModelOpen) {
      setOpenDialog(false);
      setJobToDelete(null);
    }
  };

  const jobAction = async (action: string, params: any) => {
    let allJobs: string[] = [];

    allJobs.push(params.row.id);
    // closing the madal
    handleModal(false, null);

    // setting state true to add less opacity and pointerevent none on table
    setDeleteOrCancel(true);

    if (action === "delete") {
      const payload = {
        uid: user.uid,
        module: params.row.model,
        jobs_ids: allJobs,
      };
      try {
        const res = await http.delete(`${JOB_SERVER_URL}/delete-jobs`, {
          data: payload,
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
        });

        if (res.data.status === "Success") {
          props.setDoLoadJobs(true);
        }
      } catch (error) {
        props.setShowError(true);
        props.setErrorMsg("Failed to delete your job. Please try again!");
      } finally {
        setDeleteOrCancel(false);
      }
    } else if (action === "cancel") {
      try {
        const res = await http.post(
          `${JOB_SERVER_URL}/cancel?task_id=${params.row.id}&uid=${user.uid}`,
          {
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        if (res.data) {
          props.setDoLoadJobs(true);
        }
      } catch (error) {
        props.setShowError(true);

        props.setErrorMsg("Failed to cancel your job. Please try again!");
      } finally {
        setDeleteOrCancel(false);
      }
    }
  };

  const columns = [
    { field: "id", headerName: "Job ID", width: 400 },

    {
      // field: props.jobs[0] && ["maimol", "ranker","reinvent"].includes(props.jobs[0].model) ? "subtasks" : "state",
      field: "state",
      headerName: "Status",
      width: 200,
      editable: false,
      cellClassName: (params: GridCellParams<any, string>) => {
        if (params.value == null) {
          return "";
        }

        return clsx("jobs", {
          success: params.value === "SUCCESS",
          failure: params.value === "FAILURE",
          pending: params.value === "STARTED",
          revoked: params.value === "REVOKED",
        });
      },
      renderCell: (params: GridCellParams<any, string>) => {
        // if(props.jobs[0] && ["maimol", "ranker", "reinvent"].includes(props.jobs[0].model)) {
        //   return <SubTaskStatus subtasks={params.value} model={props.jobs[0].model} />
        // }

        return <span>{params.value}</span>;
      },
    },

    {
      field: "received",
      headerName: "Received",
      width: 250,
      editable: false,
      renderCell: (params: GridCellParams<any, string>) => {
        const formattedDate = formatTimestamp(params.value);
        return <span>{formattedDate}</span>;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 400,
      editable: false,
      renderCell: (params: any) => {
        return (
          <JobDetails
            job={params.row}
            handleBackdrop={props.handleBackdrop}
            disableDownload={props.disableDownload}
            disableVisualize={props.disableVisualize}
            nextUrl={props.nextUrl}
            setShowError={props.setShowError}
            setErrorMsg={props.setErrorMsg}
            onSuccessfullJobSubmission={props.onSuccessfullJobSubmission}
          ></JobDetails>
        );
      },
    },
    {
      field: "jobargs",
      headerName: "Job Details",
      width: 200,
      editable: false,
      renderCell: (params: any) => {
        return <JobArgs data={params.value} />;
      },
    },
    {
      field: "delete",
      headerName: "Delete/Cancel",
      width: 200,
      editable: false,
      renderCell: (params: any) => {
        return (
          <Box>
            <IconButton
              aria-label="delete"
              onClick={() => {
                if (
                  params.row.state === "STARTED" ||
                  params.row.state === "RECEIVED"
                )
                  setJobActionType("cancel");
                else setJobActionType("delete");
                handleModal(true, params);
              }}
            >
              <Tooltip
                title={
                  params.row.state === "STARTED" ||
                  params.row.state === "RECEIVED"
                    ? "Cancel Job"
                    : "Delete Job"
                }
              >
                {params.row.state === "STARTED" ||
                params.row.state === "RECEIVED" ? (
                  <StopCircleIcon color="error" />
                ) : (
                  <DeleteIcon color="error" />
                )}
              </Tooltip>
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const handleReplay = () => {
    if (props.setDoLoadJobs) props.setDoLoadJobs(true);
  };

  // useEffect to run handleReplay every 10 seconds after the component renders
  useEffect(() => {
    const intervalId = setInterval(() => {
      handleReplay();
    }, 300000); // 300000 = 5 mintues

    // Cleanup interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures the effect runs only on mount/unmount

  return (
    <>
      <Box
        sx={{
          width: "100%",
          "& .jobs.success": {
            color: "#0d600d",
            fontWeight: "600",
          },
          "& .jobs.failure": {
            color: "#f52015",
            fontWeight: "600",
          },
          "& .jobs.pending": {
            color: "#23ae23",
            fontWeight: "700",
          },
          "& .jobs.revoked": {
            color: "#f52015",
            fontWeight: "600",
          },
        }}
      >
        <Grid container>
          <Grid item xs={10}>
            <Typography variant={"h5"} mb={1}>
              {"Jobs"}
            </Typography>
          </Grid>
          <Grid item xs={2} pr={1} sx={{ textAlign: "right" }}>
            <IconButton
              aria-label="Example"
              onClick={handleReplay}
              sx={{
                border: "1px solid grey",
                borderRadius: "2px",
                // marginBottom: "8px",
                padding: "4px",
              }}
            >
              <RefreshIcon />
            </IconButton>
          </Grid>
        </Grid>
        {/* <Typography variant={"h5"} mb={1}>
        {"Jobs"}
      </Typography> */}
        {!props.jobs && (
          <p>No jobs found in history. Submit jobs to see your history.</p>
        )}
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: "received", sort: "desc" }],
            },
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          apiRef={apiRef}
          rows={props.jobs}
          columns={columns}
          getRowHeight={() => "auto"}
          pageSizeOptions={[10, 25, 50]}
          sx={{
            opacity: deleteOrCancel ? 0.5 : 1,
            pointerEvents: deleteOrCancel ? "none" : "unset",
          }}
        />
        <></>
      </Box>

      {/* pop to to confirm from user to delete or cancel the job */}
      <Dialog
        open={openDialoag}
        onClose={() => handleModal(false, null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={{
            textAlign: "left",
          }}
        >
          <Box
            sx={{
              p: 4,
            }}
          >
            <Typography variant="h5">
              Do you want to {jobActionType} the job?
            </Typography>
            {jobToDelete && (
              <Typography color={"Highlight"} fontWeight={"bold"}>
                Job ID: {jobToDelete.row.id}
              </Typography>
            )}
            <Typography color={"error"} fontWeight={"bold"}>
              All job related data will be lost and job will removed from your
              account.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => handleModal(false, null)}>
            <Typography variant="h6">Later</Typography>
          </Button>

          <Button
            // variant="contained"
            autoFocus
            color="error"
            onClick={() => jobAction(jobActionType, jobToDelete)}
          >
            {jobActionType === "cancel" ? (
              <Typography variant="h6">Cancel Job</Typography>
            ) : (
              <Typography variant="h6">Delete Job</Typography>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default JobsTable;
