import { TabContext, TabList } from "@mui/lab";
import { Box, Card, CardContent, Grid, Tab, Typography } from "@mui/material";
import { useState } from "react";
import { PDBInput, TabPanel } from "../../components";

const chipTabs = [
  {
    id: "0",
    label: "description",
    value: (
      <>
        <Typography paragraph>
          {
            "The Protein Info tool of MoleculeGen's web-based program automatically visualizes proteins, ligands, and their interactions in a rich 3D environment."
          }
          {
            " With a single click, the Protein Info module provides in-depth details on the selected PDB file, performs multiple analyses, curates PDB metadata, and displays a variety of additional information related to the protein structure from various databases in an attractive and presentable format."
          }
        </Typography>
        <Box
          component="img"
          src="/images/hero/protien_info_internal.png"
          alt="Protein Info Visual"
          sx={{
            width: "75%", // Reduces the width to 75% of the parent container
            height: "auto",
            mt: 4, // Adds margin-top for space above the image
            mb: 2, // Adds margin-bottom for space below the image
            mx: "auto", // Centers the image horizontally
            display: "block", // Ensures the Box behaves like a block element
            p: 2, // Adds padding around the image inside the Box
          }}
        />
      </>
    ),
  },
  {
    id: "1",
    label: "References",
    value: (
      <>
        <Grid container item direction={"column"} mt={3}>
          <Typography variant={"body1"} mb={1}>
            1. Colovos C, Yeates TO. Verification of protein structures:
            patterns of nonbonded atomic interactions. Protein Sci.
            1993;2(9):1511-1519. doi:10.1002/pro.5560020916
          </Typography>
          <Typography variant={"body1"} mb={1}>
            2. Kunzmann, Patrick, et al. "Biotite: new tools for a versatile
            Python bioinformatics library." BMC bioinformatics 24.1 (2023): 236.
          </Typography>
          <Typography variant={"body1"} mb={1}>
            3. Gowers, Richard J., et al. "MDAnalysis: a Python package for the
            rapid analysis of molecular dynamics simulations." Proceedings of
            the 15th python in science conference. Vol. 98. Austin, TX: SciPy,
            2016.
          </Typography>
          <Typography variant={"body1"} mb={1}>
            4. Michaud‐Agrawal, Naveen, et al. "MDAnalysis: a toolkit for the
            analysis of molecular dynamics simulations." Journal of
            computational chemistry 32.10 (2011): 2319-2327.
          </Typography>
          <Typography variant={"body1"} mb={1}>
            5. Cock, Peter JA, et al. "Biopython: freely available Python tools
            for computational molecular biology and bioinformatics."
            Bioinformatics 25.11 (2009): 1422.
          </Typography>
          <Typography variant={"body1"} mb={4}>
            6. UniProt Consortium. "UniProt: a worldwide hub of protein
            knowledge." Nucleic acids research 47.D1 (2019): D506-D515.
          </Typography>
        </Grid>
      </>
    ),
  },
];

function PDBInfo() {
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    //console.log(newValue);
    setTabValue(newValue);
  };

  const [tabValue, setTabValue] = useState("0");

  return (
    <Grid container sx={{ height: "auto" }} pr={2.5}>
      {/* <Outlet /> */}
      <Grid item xs={9} p={2.5}>
        {/* header */}
        <Grid component={Card} item></Grid>
        {/* Main body */}
        <Grid item rowSpacing={3} mt={3} component={Card}>
          {/* Chips */}
          <CardContent sx={{ p: 0 }}>
            <Grid item>
              <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
                  <TabList
                    value={tabValue}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    {chipTabs.map((tab, index) => (
                      <Tab key={index} label={tab.label} value={tab.id} />
                    ))}
                  </TabList>
                </Box>

                <Grid item sx={{ px: 3.75 }}>
                  {chipTabs.map((tab, index) => (
                    <TabPanel key={index} value={tab.id}>
                      <Typography>{tab.value}</Typography>
                    </TabPanel>
                  ))}
                </Grid>
              </TabContext>
            </Grid>
            {/* /content */}
          </CardContent>
        </Grid>
        {/* reference */}
      </Grid>
      {/* Side bar container */}
      <Grid
        item
        container
        direction={"column"}
        xs={3}
        sx={{ borderLeft: 1, borderColor: "grey.300" }}
        component={Card}
        mt={2.5}
      >
        {/* <CardContent> */}
        <PDBInput />
        {/* </CardContent> */}
      </Grid>
    </Grid>
  );
}

export default PDBInfo;
