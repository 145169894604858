import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { store, type RootState } from "../../app/store";

import { JOB_SERVER_URL } from "../../config";
import http from "../../net/http-common";
import {
  maiFilters,
  medicinalChemistryFilters,
  physicoChemicalProp,
  toxicGroupsFIlters,
} from "./propertiesHelper";

interface stateType {
  loading: boolean;
  properties: {
    data: any | null;
    jobId: string;
    loading: boolean;
  };
}

const initialState: stateType = {
  loading: false,
  properties: {
    data: null,
    jobId: "",
    loading: false,
  },
};

export const fetchScreenieProperties = createAsyncThunk(
  "screenieProperties/fetchScreenieProperties",
  async (task_id: string) => {
    return http
      .get(
        `${JOB_SERVER_URL}/screenie/results?task_id=${task_id}&smiles_column=smiles&as_file=false`
      )
      .then((response) => response.data)
      .catch((error) => {
        // console.log(error);
      });
  }
);

const screeniePropertiesSlice = createSlice({
  name: "screenieProperties",

  initialState,
  reducers: {
    setProperties(state, action) {
      ////console.log(action);
    },
  },
  extraReducers: (builder) => {
    // Fetch Properties
    builder
      .addCase(
        fetchScreenieProperties.fulfilled,
        (state: stateType, action: any) => {
          let physicoChemicalPropData: any[] = [],
            maiFiltersData: any[] = [],
            medicinalChemistryFiltersData: any[] = [],
            toxicGroupsFIltersData: any[] = [],
            allData: any[] = [];

       

          action.payload.results.map((item: any, index: any) => {
            let pcp: any = {}; // physico chemical properties
            let mfd: any = {}; // mai filters
            let mcf: any = {}; // medicinal chemistry filter
            let tgf: any = {}; // toxic group

            // console.log(item);

            // converting toxic properties into true and false

            item["BRENK"] = item["BRENK"] ? "PASS" : "FAIL";
            item["NIH"] = item["NIH"] ? "PASS" : "FAIL";
            item["PAINS_A"] = item["PAINS_A"] ? "PASS" : "FAIL";
            item["PAINS_B"] = item["PAINS_B"] ? "PASS" : "FAIL";
            item["PAINS_C"] = item["PAINS_C"] ? "PASS" : "FAIL";
            item["ZINC"] = item["ZINC"] ? "PASS" : "FAIL";
            item["id"] = item.ID ? item.ID : index;

            for (const key in item) {
              if (physicoChemicalProp.includes(key)) pcp[key] = item[key];
              else if (maiFilters.includes(key)) mfd[key] = item[key];
              else if (medicinalChemistryFilters.includes(key))
                mcf[key] = item[key];
              else if (toxicGroupsFIlters.includes(key)) tgf[key] = item[key];

              pcp["smiles"] = item["smiles"];
              pcp["mol2"] = item["mol2"];
              mfd["smiles"] = item["smiles"];
              mcf["smiles"] = item["smiles"];
              tgf["smiles"] = item["smiles"];

              pcp["svg"] = item["svg"];
              mfd["svg"] = item["svg"];
              mcf["svg"] = item["svg"];
              tgf["svg"] = item["svg"];

              if (item["name"] !== undefined && item["name"] !== null) {
                // if name key exists
                pcp["name"] = item["name"];
                mfd["name"] = item["name"];
                mcf["name"] = item["name"];
                tgf["name"] = item["name"];
              }
            }

            pcp["id"] = item.id;
            mfd["id"] = item.id;
            mcf["id"] = item.id;
            tgf["id"] = item.id;

            physicoChemicalPropData.push(pcp);
            maiFiltersData.push(mfd);
            medicinalChemistryFiltersData.push(mcf);
            toxicGroupsFIltersData.push(tgf);
            allData.push(item);
          });

          state.properties.data = [
            physicoChemicalPropData,
            medicinalChemistryFiltersData,
            maiFiltersData,
            toxicGroupsFIltersData,
            allData,
          ];
          state.properties.jobId = action.payload.task_id;
          // console.log(state.properties.data);
          state.properties.loading = false;
        }
      )
      .addCase(fetchScreenieProperties.rejected, (state, action: any) => {
        state.properties.loading = false;
      })
      .addCase(fetchScreenieProperties.pending, (state) => {
        state.properties.loading = true;
      });
  },
});

export const { setProperties } = screeniePropertiesSlice.actions;
export const selectAdmetProperties = (state: RootState) => state.admeProperty;
export default screeniePropertiesSlice.reducer;
