import React, { useEffect, useRef, useState } from "react";
import { TabContext, TabList } from "@mui/lab";
import { Tab, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { TabPanel } from "../../components";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import { StateObjectRef } from "molstar/lib/mol-state";

import http from "../../net/http-common";
import Grid from "@mui/material/Grid";
import Viewer from "../../components/common/MolViewer";
import {
  Card,
  CardContent,
  Collapse,
  CircularProgress,
  Alert,
  Snackbar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Loaders from "../../components/common/Loaders";
import { AutoMolInput, JobsTable } from "../../components";
import axios from "axios";
import { useUserAuth } from "../../context";
import { errorMessages } from "../../common_variables/ErrorMsgs";
import IndefiniteLoader from "../../components/common/IndefiniteLoader";
import { JOB_SERVER_URL } from "../../config";

const menuItems = [
  {
    value: "7te0",
    label:
      "7TE0 - SARS-COV-2 main protease in complex with inhibitor pf-07321332",
  },
  {
    value: "6x8f",
    label: "6X8F - crystal structure of tyk2 with compound 11",
  },
  {
    value: "5y0z_At1",
    label:
      "5Y0Z_At1 - human sirt2 in complex with a specific inhibitor, npd11033",
  },
  {
    value: "4zzi_1ns",
    label: "4ZZI_1ns - sirt1/activator/inhibitor complex",
  },
  {
    value: "4jsr_t1",
    label:
      "4JSR_t1 - bace crystal structure with tricyclic aminothiazine inhibitor",
  },
  {
    value: "2rg5_t1",
    label:
      "2RG5_t1 - phenylalanine pyrrolotriazine p38 alpha map kinase inhibitor compound 11b",
  },
  {
    value: "2p16_t1",
    label: "2P16_t1 - factor xa in complex with the inhibitor apixaban",
  },
];

const tableHeaders: { align: "left"; label: string }[] = [
  { align: "left", label: "ID" },
  { align: "left", label: "Image" },
  { align: "left", label: "SMILES" },
  { align: "left", label: "Binding Affinity" },
  { align: "left", label: "QED" },
  { align: "left", label: "SA" },
  { align: "left", label: "LogP" },
  { align: "left", label: "Penalized LogP" },
  { align: "left", label: "Undesired Cycles" },
];
function Limo() {
  const [target, setTarget] = useState("7te0");
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [targetData, setTargetData] = useState<any>(null);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [currentRef, setCurrentRef] = useState<StateObjectRef>(null);

  const MODEL_NAME = "automol";

  const [formLoader, setFormLoader] = useState(false);
  const [jobsVisible, setJobsVisible] = useState(false);
  const [jobstatusVisible, setJobstatusVisible] = useState(false);
  const [doLoadJobs, setDoLoadJobs] = useState(true);
  const [jobID, setJobID] = useState(-1);
  const [rows, setRows] = useState<[]>();
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    //console.log(newValue);
    setTabValue(newValue);
  };

  const [tabValue, setTabValue] = useState("0");
  const navigate = useNavigate();
  const { user } = useUserAuth();
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showError, setShowError] = useState(false);

  let viewer = useRef<Viewer>();

  const handleChange = (event: SelectChangeEvent) => {
    setInProgress(true);
    setIsTableVisible(false);
    setTarget(event.target.value);
  };

  const submitNewJob = () => {
    navigate("submit");
  };
  const handleBackdrop = (status: boolean) => {
    setRequestInProgress(status);
  };

  useEffect(() => {
    setInProgress(true);
    http
      .post(`/models/limo`, "", {
        params: {
          target: target,
          num_max: "10",
        },
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response: any) => {
        ////console.log(response)
        setTargetData(response.data);
        setInProgress(false);
        setIsTableVisible(true);
      })
      .catch((error) => {
        setShowError(true);
        setErrorMsg("Something went wrong. Error Loading Examples Table");
        setInProgress(false);
        setIsTableVisible(true);
        setTargetData([]);
      });
  }, [target]);

  const initViewer = async (selectedRow: number) => {
    viewer.current = new Viewer();
    await viewer.current.init("limo-molviewer-" + selectedRow, {
      layoutShowControls: false,
      viewportShowExpand: false,
      collapseLeftPanel: true,
      layoutShowSequence: false,
    });
  };

  const updateViewer = async (
    data: string,
    format:
      | "mmcif"
      | "cifCore"
      | "pdb"
      | "pdbqt"
      | "gro"
      | "xyz"
      | "mol"
      | "sdf"
      | "mol2",
    selectedRow: number
  ) => {
    initViewer(selectedRow).then(() => {
      const _ref = viewer?.current?.loadStructureFromData(
        data,
        format,
        { dataLabel: "" },
        currentRef
      );
    });
  };
  const handleSubmitLoader = (state: boolean) => {
    setFormLoader(state);
  };
  useEffect(() => {
    if (!doLoadJobs || !user) return;

    axios
      .get(`${JOB_SERVER_URL}/userjobs`, {
        params: {
          user_id: user.uid,
          model_name: MODEL_NAME,
          start: "0",
          end: "10",
        },
        headers: {
          accept: "application/json",
        },
      })
      .then((res) => {
        ////console.log(res.data)
        setDoLoadJobs(false);
        setRows(res.data);
        ////console.log('jobs', res.data)
      })
      .catch((error) => {
        setShowError(true);
        setErrorMsg(errorMessages.jobTableError);
        setDoLoadJobs(false);
        setRows([]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doLoadJobs]);

  useEffect(() => {
    if (selectedRow === -1) return;
    updateViewer(targetData[selectedRow].mol, "mol2", selectedRow);
  }, [selectedRow, targetData]);

  const postHandleSubmit = (res: { data: { task_id: number } }) => {
    setJobID(res.data.task_id);
    setJobsVisible(true);
    setFormLoader(false);
    setDoLoadJobs(true);
  };
  const handleErrorSubmit = (error: any) => {
    ////console.log(error);
    setShowError(true);

    //if you want more specific error msg

    // if (error.response) {
    //   if(error.response.status>=500)
    //   {
    //     setErrorMsg(errorMessages.serverError)
    //   }
    //   else if(error.response.status>=400)
    //   {
    //     setErrorMsg(errorMessages.clientError)
    //   }
    //   else
    //   setErrorMsg(errorMessages.genericError)
    // }
    // else if (error.request) {
    //   setErrorMsg(errorMessages.connectionError)
    // }
    // else {
    //   setErrorMsg(errorMessages.requestError)
    // }

    setErrorMsg(errorMessages.submitJobError);
    setFormLoader(false);
  };
  const chipTabs = [
    {
      id: "0",
      label: "description",
      value: (
        <>
          <Typography>
            Leader is a VAE latent space-based generative modeling application
            for the efficient optimization and generation of drug-like,
            synthesizable, high-affinity compounds. Leader efficiently optimizes
            small-molecule lead/hit candidates based on a user-defined set of
            features with high binding affinity to target proteins.
          </Typography>
          <Box
            component="img"
            src="/images/hero/leader-dashcard-2.png"
            alt="Protein Info Visual"
            sx={{
              width: "80%",
              height: "auto",
              mt: 4,
              mb: 2,
              mx: "auto",
              display: "block",
              p: 2,
            }}
          />
          <Typography sx={{ pb: 6 }}>
            Additionally, it can carry out substructure-constrained property
            optimization, which is necessary for designing compounds with better
            activity or pharmacologic characteristics during the lead
            optimization stage.
          </Typography>
        </>
      ),
    },
    {
      id: "1",
      label: "References",
      value: (
        <>
          <Stack direction={"column"} mt={3}>
            {/* Add more references here */}
            <Typography variant={"body1"}>
              Our lead optimization is based on LIMO [1], a VAE-based
              deep-learning tool that generates molecules with targeted
              properties.
            </Typography>
            <Typography variant={"body1"} mb={2}>
              LIMO accelerates drug discovery by efficiently optimizing
              molecular structures according to the desired properties.
            </Typography>
            <Typography variant={"body1"} mb={4}>
              [1] Eckmann, Peter, et al. "LIMO: Latent Inceptionism for Targeted
              Molecule Generation."
              <i>International Conference on Machine Learning</i>, PMLR, 2022.
            </Typography>
          </Stack>
        </>
      ),
    },
  ];

  return (
    <>
      <Snackbar
        open={showError}
        autoHideDuration={9000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <Grid container px={3} mt={2} spacing={2}>
        <Grid item xs={8}>
          {/* Description */}
          <Card sx={{ height: 1 }}>
            <CardContent sx={{ p: 0 }}>
              <Grid item>
                <TabContext value={tabValue}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
                    <TabList
                      value={tabValue}
                      onChange={handleTabChange}
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      {chipTabs.map((tab, index) => (
                        <Tab key={index} label={tab.label} value={tab.id} />
                      ))}
                    </TabList>
                  </Box>

                  <Grid item sx={{ px: 2 }}>
                    {chipTabs.map((tab, index) => (
                      <TabPanel key={index} value={tab.id}>
                        <Typography>{tab.value}</Typography>
                      </TabPanel>
                    ))}
                  </Grid>
                </TabContext>
              </Grid>
              {/* /content */}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card sx={{ height: 1 }}>
            <CardContent>
              <AutoMolInput
                postHandleSubmit={postHandleSubmit}
                handleErrorSubmit={handleErrorSubmit}
                handleSubmitLoader={handleSubmitLoader}
              />
              <Grid container>
                <Grid item>{formLoader && <CircularProgress />}</Grid>
              </Grid>
              {/* {jobsVisible && <Typography>Job {jobID} submitted</Typography>} */}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid sx={{ width: "50%", mx: "auto" }}>
        {jobsVisible && (
          <Alert
            onClose={() => {
              setJobsVisible(false);
            }}
            sx={{ mt: 2 }}
            variant="filled"
            severity="success"
          >{`Job submitted with id ${jobID}`}</Alert>
        )}
      </Grid>
      <Grid container p={3}>
        <Card style={{ width: "100%" }}>
          <CardContent>
            {doLoadJobs && <Loaders type={"table"} />}
            {!doLoadJobs && (
              <JobsTable
                jobs={rows}
                handleBackdrop={handleBackdrop}
              ></JobsTable>
            )}
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

export default Limo;
