import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: "Gilroy",
    fontWeightRegular: 500,
    h1: {
      fontWeight: "bold",
    },
    h2: {
      fontWeight: "bold",
    },
    h3: {
      fontWeight: "bold",
    },
    h4: {
      fontWeight: "bold",
    },
    h5: {
      fontWeight: "bold",
    },
    h6: {
      fontWeight: "bold",
      fontSize: "1.1rem",
    },
  },
  palette: {
    primary: {
      main: "#582FF1",
      contrastText: "#fff",
    },
    secondary: {
      main: "#6C1EA1",
    },
    text: {
      primary: "#040916",
      secondary: "#7F7D95",
    },
    common: {
      black: "#030917",
    },
    grey: {
      100: "#F2F6FF",
      200: "#E7EBF9",
      300: "#D9DDEA",
      400: "#C5C9D6",
      500: "#9FA3B0",
      600: "#7E828E",
      700: "#575B67",
      800: "#454954",
      900: "#282B35",
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            // transform: 'translate(0px, -24px) scale(1);'
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "6px 16px 16px",
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: "4px 30px 30px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          border: "1px solid var(--gray-2, #E9ECEF)",
          background: "#F6F6F9",
          ".MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          padding: "0.8rem 0.8rem",
          borderRadius: "10px",
          fontWeight: 600,
          lineHeight: "normal",
          textTransform: "none",
          "&.Mui-disabled": {
            background: "#BDADF9",
            color: "#fff",
          },
        },
        containedPrimary: {
          backgroundImage:
            "linear-gradient(93deg, #582FF1 7.27%, #6644E8 77.03%)",
          "&:disabled": {
            backgroundImage: "none",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          "&:first-of-type": {
            paddingLeft: "30px",
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          ".MuiTableCell-root": {
            color: "var(--shade-600, #7D828F)",
          },
        },
      },
    },
  },
});

export default theme;
