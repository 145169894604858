import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../../net/http-common";
import { JOB_SERVER_URL } from "../../config";
// import { useNavigate } from "react-router-dom";
// const navigate = useNavigate();

interface stateType {
  status: "idle" | "loading" | "failed" | "successful";
  NCIProperties: {
    rowData: any[];
    plot: any;
  };
  RBFEProperties: {
    rowData: any[];
  };
  MDProperties: {
    pdbUrl: string;
    xtcUrl: string;
  };
}

const initialState: stateType = {
  status: "idle",
  NCIProperties: {
    rowData: [],
    plot: null,
  },
  RBFEProperties: {
    rowData: [],
  },
  MDProperties: {
    pdbUrl: "",
    xtcUrl: "",
  },
};

export const fetchRBFEResults = createAsyncThunk(
  "lodestar/fetchRBFEResults",
  async (task_id: string) => {
    //     return http
    //       .get(
    //         `${JOB_SERVER_URL}/pmsfilter/results?task_id=${task_id}&as_file=false`
    //       )
    //       .then((response) => response.data)
    //       .catch((error) => {
    //         console.log(error);
    //       });
    return RBFEData;
  }
);

export const fetchMDResults = createAsyncThunk(
  "lodestar/fetchMDResults",
  async (task_id: string) => {
    try {
      return await http.get(
        `${JOB_SERVER_URL}/md_simulation/results/${task_id}`,
        {
          headers: {
            accept: "application/json",
          },
        }
      );
    } catch (error) {
      // console.log("error : ", error);
    }
  }
);

export const fetchNCIResults = createAsyncThunk(
  "lodestar/fetchNCIResults",
  async (task_id: string) => {
    try {
      const response = await http.get(
        `${JOB_SERVER_URL}/nci_analysis/results/${task_id}?as_file=${false}`,
        {
          headers: {
            accept: "application/json",
          },
        }
      );
      return response.data.result;
    } catch (error) {}
  }
);

const lodestarSlice = createSlice({
  name: "lodestar",

  initialState,
  reducers: {
    setProperties(state, action) {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRBFEResults.fulfilled, (state: stateType, action: any) => {
        state.RBFEProperties.rowData = action.payload;
        state.status = "successful";
      })
      .addCase(fetchRBFEResults.rejected, (state, action: any) => {
        state.status = "failed";
      })
      .addCase(fetchRBFEResults.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMDResults.fulfilled, (state: stateType, action: any) => {
        state.status = "successful";
        state.MDProperties.pdbUrl = action.payload.data.result.protein;
        state.MDProperties.xtcUrl = action.payload.data.result.trajectory;
      })
      .addCase(fetchMDResults.rejected, (state, action: any) => {
        state.status = "failed";
      })
      .addCase(fetchMDResults.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchNCIResults.fulfilled, (state: stateType, action: any) => {
        state.NCIProperties.rowData = action.payload.data;
        state.NCIProperties.plot = action.payload.output_png;
        state.status = "successful";
      })
      .addCase(fetchNCIResults.rejected, (state, action: any) => {
        state.status = "failed";
      })
      .addCase(fetchNCIResults.pending, (state) => {
        state.status = "loading";
      });
  },
});

export const { setProperties } = lodestarSlice.actions;
export default lodestarSlice.reducer;

const RBFEData = [
  {
    id: 1,
    LigandAtomMapping: "lig_ejm_46 To lig_jmc_28",
    score: 0.904,
    rbfe: 0.003,
    uncertainty: 0.181,
  },
];
