import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  Modal,
} from "@mui/material";
import React, { useState } from "react";

import { JOB_SERVER_URL } from "../../config";
import { useNavigate } from "react-router-dom";
import {
  fetchProperties,
  selectProperties,
} from "../../views/Properties/propertiesSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  fetchAllRowsFilters,
  fetchMaiFilters,
  fetchMedchemFilters,
  fetchPropertiesV2,
  fetchToxicFilters,
} from "../../views/Properties/propertiesSliceV2";
import { useDispatch } from "react-redux";
import { fetchAdmeProperties } from "../../views/AdmeTox/AdmeSlice";
import { fetchAutoDockProperties } from "../../views/Docking/dockingSlice";
import { fetchPromiscuityResults } from "../../views/Promiscuity/promiscuitySlice";
import http from "../../net/http-common";
import { fetchScreenieProperties } from "../../views/Properties/PropertiesSliceV3";
import { fetchLiftFilterData } from "../../views/LiftFilter/liftFilterSlice";
import { fetchRankerProperties } from "../../views/Ranker/rankerSlice";
import { fetchLeaderReinventProperties } from "../../views/leaderReinvent/leaderReinventSlice";
import { fetchBioisostericProperties } from "../../views/Bioisosteric/BioisostericSlice";
import { useUserAuth } from "../../context";
import JobStatusDialog from "./JobStatusDialog";
import { errorMessages } from "../../common_variables/ErrorMsgs";
import { fetchNCIResults, fetchRBFEResults } from "../../views/Lodestar/LodestarSlice";

interface Job {
  job: any;
  handleBackdrop?: (status: boolean) => void;
  disableDownload?: boolean;
  disableVisualize?: boolean;
  nextUrl?: string;
  setShowError?: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMsg?: React.Dispatch<any>;
  onSuccessfullJobSubmission?: (jobId: number) => void;
}

function downloadAutoMolResults(task_id: string) {
  http
    .get(`${JOB_SERVER_URL}/automol/results`, {
      params: {
        task_id: task_id,
      },
      headers: {
        accept: "application/json",
      },
    })
    .then((response) => {
      const href = window.URL.createObjectURL(new Blob([response.data]));

      const anchorElement = document.createElement("a");

      anchorElement.href = href;
      anchorElement.download = `automol_${task_id}.csv`;

      document.body.appendChild(anchorElement);
      anchorElement.click();

      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href);
    })
    .catch((error) => {
      //console.log("error: ", error);
    });
}

function downloadTargetDiffResults(task_id: string, MODEL_NAME: string) {
  http
    .get(`${JOB_SERVER_URL}/targetdiff/results`, {
      params: {
        task_id: task_id,
        model_name: MODEL_NAME,
      },
      headers: {
        accept: "application/json",
      },
    })
    .then((response) => {
      const href = window.URL.createObjectURL(new Blob([response.data]));

      const anchorElement = document.createElement("a");

      anchorElement.href = href;
      anchorElement.download = `diffmol_${task_id}.csv`;

      document.body.appendChild(anchorElement);
      anchorElement.click();

      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href);
    })
    .catch((error) => {
      //console.log("error: ", error);
    });
}

function downloadMaimolResults(task_id: string) {
  http
    .get(`${JOB_SERVER_URL}/maimol_pipeline/results`, {
      params: {
        task_id: task_id,
      },
      headers: {
        accept: "application/json",
      },
    })
    .then((response) => {
      const href = window.URL.createObjectURL(new Blob([response.data]));

      const anchorElement = document.createElement("a");

      anchorElement.href = href;
      anchorElement.download = `${task_id}.csv`;

      document.body.appendChild(anchorElement);
      anchorElement.click();

      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href);
    })
    .catch((error) => {
      //console.log("error: ", error);
    });
}

function downloadDiffDockResults(task_id: string) {
  http
    .get(`${JOB_SERVER_URL}/diffdock/results`, {
      params: {
        task_id: task_id,
      },
      headers: {
        accept: "application/json",
      },
    })
    .then((response) => {
      const href = window.URL.createObjectURL(new Blob([response.data.ligand]));

      const anchorElement = document.createElement("a");

      anchorElement.href = href;
      anchorElement.download = `diffdock_${task_id}.sdf`;

      document.body.appendChild(anchorElement);
      anchorElement.click();

      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href);
    })
    .catch((error) => {
      console.log("error: ", error);
    });
}

function downloadADMEToxResults(task_id: string) {
  http
    .get(`${JOB_SERVER_URL}/adme_tox/results`, {
      params: {
        task_id: task_id,
        as_file: true,
      },
      headers: {
        accept: "application/json",
      },
    })
    .then((response) => {
      const href = window.URL.createObjectURL(new Blob([response.data]));

      const anchorElement = document.createElement("a");

      anchorElement.href = href;
      anchorElement.download = `adme_${task_id}.csv`;

      document.body.appendChild(anchorElement);
      anchorElement.click();

      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href);
    })
    .catch((error) => {
      //console.log("error: ", error);
    });
}

function downloadAutoDockResults(task_id: string, MODEL_NAME?: string) {
  http
    .get(`${JOB_SERVER_URL}/dock/results`, {
      params: {
        model_name: MODEL_NAME,
        task_id: task_id,
        as_file: true,
      },
      headers: {
        accept: "application/json",
      },
    })
    .then((response) => {
      const href = window.URL.createObjectURL(new Blob([response.data]));

      const anchorElement = document.createElement("a");

      anchorElement.href = href;
      anchorElement.download = `docking_scores_${task_id}.csv`;

      document.body.appendChild(anchorElement);
      anchorElement.click();

      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href);
    })
    .catch((error) => {
      //console.log("error: ", error);
    });
}

function downloadPromiscuityResults(task_id: string) {
  http
    .get(`${JOB_SERVER_URL}/pmsfilter/results`, {
      params: {
        task_id: task_id,
        as_file: true,
      },
      headers: {
        accept: "application/json",
      },
    })
    .then((response) => {
      const href = window.URL.createObjectURL(new Blob([response.data]));

      const anchorElement = document.createElement("a");

      anchorElement.href = href;
      anchorElement.download = `promiscuity_scores_${task_id}.csv`;

      document.body.appendChild(anchorElement);
      anchorElement.click();

      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href);
    })
    .catch((error) => {
      //console.log("error: ", error);
    });
}

function downloadScreenieResults(task_id: string) {
  http
    .get(`${JOB_SERVER_URL}/screenie/results`, {
      params: {
        task_id: task_id,
        as_file: true,
      },
      headers: {
        accept: "application/json",
      },
    })
    .then((response) => {
      const href = window.URL.createObjectURL(new Blob([response.data]));

      const anchorElement = document.createElement("a");

      anchorElement.href = href;
      anchorElement.download = `screenie_${task_id}.csv`;

      document.body.appendChild(anchorElement);
      anchorElement.click();

      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href);
    })
    .catch((error) => {
      //console.log("error: ", error);
    });
}

function downloadliftResults(task_id: string) {
  http
    .get(`${JOB_SERVER_URL}/lift/results`, {
      params: {
        task_id: task_id,
        as_file: true,
      },
      headers: {
        accept: "application/json",
      },
    })
    .then((response) => {
      const href = window.URL.createObjectURL(new Blob([response.data]));

      const anchorElement = document.createElement("a");

      anchorElement.href = href;
      anchorElement.download = `${task_id}.csv`;

      document.body.appendChild(anchorElement);
      anchorElement.click();

      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href);
    })
    .catch((error) => {
      //console.log("error: ", error);
    });
}

function downloadReinventResults(task_id: string) {
  http
    .get(`${JOB_SERVER_URL}/reinvent/results`, {
      params: {
        task_id: task_id,
        as_file: true,
      },
      headers: {
        accept: "application/json",
      },
    })
    .then((response) => {
      const href = window.URL.createObjectURL(new Blob([response.data]));

      const anchorElement = document.createElement("a");

      anchorElement.href = href;
      anchorElement.download = `reinvent_${task_id}.csv`;

      document.body.appendChild(anchorElement);
      anchorElement.click();

      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href);
    })
    .catch((error) => {
      //console.log("error: ", error);
    });
}

function downloadRBFEResults(task_id: string) {
  http
    .get(`${JOB_SERVER_URL}/rbfe/results/${task_id}`, {
      headers: {
        accept: "application/json",
      },
    })
    .then((response) => {
      const link = document.createElement("a");
      link.href = response.data.result.output_json;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      //console.log("error: ", error);
    });
}

function downloadMDResults(task_id: string, resultType?: string) {
  http
    .get(`${JOB_SERVER_URL}/md_simulation/results/${task_id}`, {
      headers: {
        accept: "application/json",
      },
    })
    .then((response) => {
      const link = document.createElement("a");
      if (resultType === "protein") {
        link.href = response.data.result.protein;
      } else {
        link.href = response.data.result.trajectory;
      }
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      //console.log("error: ", error);
    });
}

function downloadNCIResults(task_id: string, resultType?: string) {
  http
    .get(`${JOB_SERVER_URL}/nci_analysis/results/${task_id}`, {
      headers: {
        accept: "application/json",
      },
    })
    .then((response) => {
      const link = document.createElement("a");
      if (resultType === "csv") {
        link.href = response.data.result.output_csv;
      } else {
        link.href = response.data.result.output_png;
      }
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })

    .catch((error) => {
      //console.log("error: ", error);
    });
}

const fetchMDResults = async (task_id: string) => {
  try {
    const response = await http.get(
      `${JOB_SERVER_URL}/md_simulation/results/${task_id}`,
      {
        headers: {
          accept: "application/json",
        },
      }
    );

    const protein = await fetch(response.data.result.protein, {
      method: "GET",
    });
    const proteinBlob = await protein.blob();
    const pdbFile = new File([proteinBlob], "structure1.pdb");

    const trajectoryResponse = await fetch(response.data.result.trajectory, {
      method: "GET",
    });
    const trajBlob = await trajectoryResponse.blob(); // Use .blob() to get binary data
    const trajFile = new File([trajBlob], "trajectory1.xtc", {
      type: "application/octet-stream",
    });

    return { pdbFile, trajFile };
  } catch (error) {
    // console.error("Error fetching MD results:", error);
  }
};


function JobDetails(props: Job) {
  const MODEL_NAME: string = props.job.model;
  const TASK_ID: string = props.job.id;

  const from_model: boolean = true;
  const [resultAvailable, setResultAvailable] = useState(true);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [smiles, setSmiles] = useState("");
  const [smilesFile, setSmilesFile] = useState("");
  const [downloadResultLoader, setDownloadResultLoader] =
    useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [dialogData, setDialogData] = useState(null);

  const { user } = useUserAuth();

  const dispatchForAction = useDispatch<any>();

  const [open, setOpen] = React.useState(false);
  const dispatch = useAppDispatch();
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#d99e9e",
    pt: 2,
    px: 4,
    pb: 3,
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();

  const visualizeResults = async () => {
    // console.log(props.job.id);
    // if ())
    // if (MODEL_NAME === "targetdiff")
    //   launchPropertyExplorer(MODEL_NAME, TASK_ID, from_model);

    // if (MODEL_NAME === "diffdock")
    //   launchDockingViewer(MODEL_NAME, TASK_ID, from_model);

    switch (MODEL_NAME) {
      case "targetdiff":
        launchPropertyExplorer(MODEL_NAME, TASK_ID, from_model);
        break;
      case "tagmol":
        launchPropertyExplorer(MODEL_NAME, TASK_ID, from_model);
        break;
      case "diffdock":
        launchDockingViewer(MODEL_NAME, TASK_ID, from_model);
        break;
      case "adme_tox":
        dispatchForAction(fetchAdmeProperties(props.job.id));
        break;
      case "autodock":
        dispatchForAction(fetchAutoDockProperties({ task_id: props.job.id }));
        // dispatchForAction(fetchPreparedLigands(props.job.id));
        break;
      case "unidock":
        dispatchForAction(
          fetchAutoDockProperties({
            task_id: props.job.id,
            model_name: MODEL_NAME,
          })
        );
        break;
      case "promiscuity":
        dispatchForAction(fetchPromiscuityResults(props.job.id));
        break;
      case "screenie":
        dispatchForAction(fetchScreenieProperties(props.job.id));
        break;
      case "lift":
        dispatchForAction(fetchLiftFilterData(props.job.id));
        break;
      case "ranker":
        // clearing selected interactions filter and tab
        localStorage.removeItem("selectedInteractions");
        localStorage.removeItem("rankerCurrTab");
        localStorage.removeItem("rankerCustomInteractions");
        dispatchForAction(fetchRankerProperties({ task_id: props.job.id }));
        break;
      case "reinvent":
        dispatchForAction(fetchLeaderReinventProperties(props.job.id));
        break;
      case "bioisosteric":
        dispatchForAction(fetchBioisostericProperties(props.job.id));
        break;
      case "rbfe":
        dispatchForAction(fetchRBFEResults(props.job.id));
        break;
      case "md_simulation":
        const MDVisualizationData = await fetchMDResults(props.job.id);
        navigate("/lab/lodestar/md/visualize", {
          state: {
            pdbID: "",
            pdbFile: MDVisualizationData.pdbFile,
            trajectoryFile: MDVisualizationData.trajFile,
            FileType: "pdb",
          },
        });
        break;
      case "nci":
        dispatchForAction(fetchNCIResults(props.job.id));
        break;
      default:
        break;
    }
    if (props.nextUrl) {
      navigate(props.nextUrl);
    }
  };

  function launchPropertyExplorer(
    model_name: string,
    job_id: string,
    from_model: boolean
  ) {
    const headerName = "smiles";

    if (props.handleBackdrop) props.handleBackdrop(true);
    setRequestInProgress(true);
    dispatch(
      fetchPropertiesV2({
        smiles,
        smilesFile,
        headerName,
        from_model,
        model_name,
        job_id,
      })
    )
      .then((res: any) => {
        const smilesArr: string[] = [];
        if (res.payload.properties) {
          res.payload.properties.map((item: any) => {
            smilesArr.push(item.smiles);
          });
        }
        return smilesArr;
      })
      .then((submittedSmiles: string[]) => {
        dispatch(fetchMedchemFilters({ submittedSmiles }));
        return submittedSmiles;
      })
      .then((submittedSmiles: string[]) => {
        dispatch(fetchMaiFilters({ submittedSmiles }));
        return submittedSmiles;
      })
      .then((submittedSmiles: string[]) => {
        dispatch(fetchToxicFilters({ submittedSmiles }));
        return submittedSmiles;
      })
      .then(() => {
        dispatch(fetchAllRowsFilters());
      })
      .then(() => {
        if (props.handleBackdrop) props.handleBackdrop(false);
        navigate("/virtual-screening/properties-v2/filter");
      })
      .catch((error) => {
        if (props.handleBackdrop) props.handleBackdrop(false);
        console.log("error while visualizing result", error);
        props.setShowError(true);
        props.setErrorMsg("Jobs has been failed for a given Job Id");
      });
  }

  function launchADMEWorkflow(
    model_name: string,
    job_id: string,
    from_model: boolean
  ) {
    navigate("/models/adme", {
      state: {
        model_name: model_name,
        job_id: job_id,
        from_model: true,
      },
    });
  }

  const launchADME = () => {
    launchADMEWorkflow(MODEL_NAME, TASK_ID, true);
  };

  function launchDockingViewer(
    model_name: string,
    job_id: string,
    from_model: boolean
  ) {
    navigate("/docking/viewer", {
      state: {
        model_name: model_name,
        job_id: job_id,
        from_model: true,
      },
    });
  }

  const downloadResults = () => {
    setDownloadResultLoader(true);
    if (MODEL_NAME === "diffdock") {
      downloadDiffDockResults(TASK_ID);
    }

    if (MODEL_NAME === "targetdiff" || MODEL_NAME === "tagmol") {
      downloadTargetDiffResults(TASK_ID, MODEL_NAME);
    }

    if (MODEL_NAME === "automol") {
      downloadAutoMolResults(TASK_ID);
    }

    if (MODEL_NAME === "adme_tox") {
      downloadADMEToxResults(TASK_ID);
    }

    if (MODEL_NAME === "autodock") {
      downloadAutoDockResults(TASK_ID);
    }

    if (MODEL_NAME === "unidock") {
      console.log("download unidock result");

      downloadAutoDockResults(TASK_ID, MODEL_NAME);
    }

    if (MODEL_NAME === "promiscuity") {
      downloadPromiscuityResults(TASK_ID);
    }
    if (MODEL_NAME === "screenie") {
      downloadScreenieResults(TASK_ID);
    }
    if (MODEL_NAME === "maimol") {
      downloadMaimolResults(TASK_ID);
    }

    if (MODEL_NAME === "lift") {
      downloadliftResults(TASK_ID);
    }
    if (MODEL_NAME === "reinvent") {
      downloadReinventResults(TASK_ID);
    }

    if (MODEL_NAME === "rbfe") {
      downloadRBFEResults(TASK_ID);
    }

    if (MODEL_NAME === "nci_analysis") {
      downloadNCIResults(TASK_ID);
    }

    setTimeout(() => {
      setDownloadResultLoader(false);
    }, 2000);
  };


  const exploreResult = async () => {
    try {
      const rankerStatus = await http.get(
        `${JOB_SERVER_URL}/ranker/status?maimol_id=${TASK_ID}&uid=${user.uid}`
      );
      if (rankerStatus.data.state !== "SUCCESS") {
        setDialogData(rankerStatus.data);
        setOpenDialog(true);
        return;
      }
      navigate("/genie/ranker/visualize");
      localStorage.removeItem("selectedInteractions");
      localStorage.removeItem("rankerCurrTab");
      localStorage.removeItem("rankerCustomInteractions");
      dispatchForAction(
        fetchRankerProperties({
          uid: user.uid,
          maimol_id: TASK_ID,
        })
      );
    } catch (error: any) {
      setDialogData(error.response.data.detail);
      setOpenDialog(true);
    }
  };

  const submitNCIJob = async (p: any) => {
    try {
      const body = {
        uid: user.uid,
      };

      const response = await http.post(
        `${JOB_SERVER_URL}/nci_analysis/submit?md_id=${TASK_ID}`,
        body,
        {
          headers: {
            accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      props.onSuccessfullJobSubmission(response.data.task_id);
    } catch (error) {
      props.setShowError(true);
      props.setErrorMsg(errorMessages.submitJobError);
    }
  };

  function showError() {
    alert(props.job?.traceback.spli);
  }

  return (
    <>
      <Grid container>
        {props.job.state === "SUCCESS" && (
          <>
            {!props.disableVisualize && (
              <Button
                variant="text"
                color="success"
                onClick={() => {
                  visualizeResults();
                }}
              >
                Visualize Results
              </Button>
            )}
            {!props.disableDownload && (
              <>
                <Box display={"flex"} flexDirection={"column"}>
                  <Button
                    variant="text"
                    onClick={() => {
                      downloadResults();
                    }}
                  >
                    Download Results
                  </Button>

                  {downloadResultLoader && (
                    <Box mt={-1}>
                      <LinearProgress color="success" />
                    </Box>
                  )}
                </Box>
              </>
            )}

            {MODEL_NAME === "maimol" && (
              <>
                <Button variant="text" color="success" onClick={exploreResult}>
                  Explore Results with Ranker
                </Button>
              </>
            )}

            {MODEL_NAME === "md_simulation" && (
              <>
                <Button
                  variant="text"
                  onClick={() => downloadMDResults(TASK_ID, "protein")}
                >
                  Download Protein
                </Button>

                <Button
                  variant="text"
                  onClick={() => downloadMDResults(TASK_ID)}
                >
                  Download Trajectory
                </Button>

                <Button variant="text" onClick={() => submitNCIJob(props)}>
                  Run with NCI
                </Button>
              </>
            )}

            {MODEL_NAME === "nci" && (
              <>
                <Button
                  variant="text"
                  onClick={() => downloadNCIResults(TASK_ID, "csv")}
                >
                  Download csv
                </Button>

                <Button
                  variant="text"
                  onClick={() => downloadNCIResults(TASK_ID)}
                >
                  Download Image
                </Button>
              </>
            )}

            {!props.handleBackdrop && (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={requestInProgress}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
            {/* {
            <Button
              variant="text"
              onClick={() => {
                launchADME();
              }}
            >
              Launch ADME
            </Button>
          } */}
          </>
        )}
        {props.job.state === "FAILURE" && (
          <div>
            <Button variant="text" color="error" onClick={handleOpen}>
              See Error
            </Button>
            <Modal open={open} onClose={handleClose}>
              <Box sx={{ ...style, width: 400 }}>
                <h2>Error</h2>
                <p>
                  {props.job?.traceback
                    ? props.job?.traceback.split("\n").slice(-2)[0]
                    : ""}
                </p>
              </Box>
            </Modal>
          </div>
        )}
      </Grid>
      <JobStatusDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        data={dialogData}
      />
    </>
  );
}

export default JobDetails;
