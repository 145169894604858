import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRankerProperties } from "./rankerApi";

interface stateType {
  loading: boolean;
  properties: {
    moleculeData: any | null;
    statsSummary: any | null;
    interactionsSet: [];
    singleSmileData: {
      data: any;
      jobId: string;
    };
    jobId: string;
  };
}

const initialState: stateType = {
  loading: false,
  properties: {
    moleculeData: null,
    statsSummary: null,
    interactionsSet: [],
    singleSmileData: {
      data: null,
      jobId: "",
    },
    jobId: "",
  },
};

export const fetchRankerProperties = createAsyncThunk(
  "ranker/fetchRankerProperties",
  async ({
    task_id,
    maimol_id,
    uid,
  }: {
    task_id?: string;
    maimol_id?: string;
    uid?: string;
  }) => {
    try {
      const response = await getRankerProperties({
        task_id,
        maimol_id,
        uid,
      });
      return response.data;
    } catch (error) {
      return { error };
    }
  }
);

export const rankerSlice = createSlice({
  name: "ranker",
  initialState,
  reducers: {
    setSingleSmileData: (state, action) => {
      state.properties.singleSmileData.data = action.payload.data;
      state.properties.singleSmileData.jobId = action.payload.jobId;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRankerProperties.fulfilled, (state, action) => {
        state.properties.moleculeData = action.payload.moleculeData;
        state.properties.statsSummary = action.payload.statsSummary;
        state.properties.interactionsSet =
          action.payload.interactions_set.sort();
        state.properties.jobId = action.payload.job_id;
        state.loading = false;
      })
      .addCase(fetchRankerProperties.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchRankerProperties.pending, (state) => {
        state.loading = true;
      });
  },
});
export const { setSingleSmileData } = rankerSlice.actions;
export default rankerSlice.reducer;
